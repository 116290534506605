import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '@/assets/ico-bk-logo.png';
const _hoisted_1 = {
  key: 0,
  class: "custom-dialog-wrapper"
};
const _hoisted_2 = {
  class: "custom-dialog"
};
const _hoisted_3 = {
  class: "dialog-content"
};
const _hoisted_4 = {
  class: "store-info"
};
const _hoisted_5 = {
  class: "store-name"
};
const _hoisted_6 = {
  class: "store-address"
};
const _hoisted_7 = {
  key: 0,
  class: "distance-warning"
};
const _hoisted_8 = {
  class: "dialog-footer"
};
import { ElIcon, ElButton } from 'element-plus';
import { Warning } from '@element-plus/icons-vue';
export default {
  __name: 'index',
  props: {
    visible: Boolean,
    store: Object
  },
  emits: ['update:visible', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    const closeDialog = () => {
      emits('update:visible', false);
    };
    const confirmStore = () => {
      emits('confirm');
    };
    return (_ctx, _cache) => {
      return __props.visible ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "dialog-header"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: "Burger King",
        class: "logo"
      }), _createElementVNode("p", null, "请再次确认门店位置")], -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(__props.store.storeName), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(__props.store.address), 1)]), __props.store.distance / 1000 > 5 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_unref(ElIcon), null, {
        default: _withCtx(() => [_createVNode(_unref(Warning))]),
        _: 1
      }), _cache[0] || (_cache[0] = _createElementVNode("p", null, "当前餐厅距离您较远，请确认您的取餐餐厅", -1))])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_8, [_createVNode(_unref(ElButton), {
        onClick: closeDialog,
        round: true
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("切换门店")])),
        _: 1
      }), _createVNode(_unref(ElButton), {
        type: "success",
        color: "#fe813b",
        onClick: confirmStore,
        round: true
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("确定")])),
        _: 1
      })])]), _createElementVNode("div", {
        class: "dialog-backdrop",
        onClick: closeDialog
      })])) : _createCommentVNode("", true);
    };
  }
};