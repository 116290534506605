import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "error-page"
};
const _hoisted_2 = {
  class: "error-content"
};
import { useRouter } from 'vue-router';
import { useErrorStore } from '@/stores/errorStore';
import { storeToRefs } from 'pinia';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const errorStore = useErrorStore();
    const {
      errorCode,
      errorMessage
    } = storeToRefs(errorStore);
    const goHome = () => {
      errorStore.clearError();
      router.push('/');
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("h1", null, "Oops!", -1)), _createElementVNode("h2", null, _toDisplayString(_unref(errorCode)), 1), _createElementVNode("p", null, _toDisplayString(_unref(errorMessage)), 1), _createVNode(_component_el_button, {
        class: "error-button",
        type: "primary",
        onClick: goHome
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("返回首页")])),
        _: 1
      })])]);
    };
  }
};