import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";
import _imports_0 from '@/assets/images/ic-wait-pay.png';
const _hoisted_1 = {
  class: "order-status-block"
};
const _hoisted_2 = {
  key: 0,
  class: "order-status"
};
const _hoisted_3 = {
  key: 1,
  class: "order-status"
};
const _hoisted_4 = {
  key: 2,
  class: "order-status"
};
const _hoisted_5 = {
  key: 3,
  class: "order-status"
};
const _hoisted_6 = {
  class: "description"
};
const _hoisted_7 = {
  key: 4,
  class: "order-status"
};
const _hoisted_8 = {
  class: "pickup-code-container"
};
const _hoisted_9 = {
  class: "code"
};
const _hoisted_10 = {
  key: 5,
  class: "order-status"
};
const _hoisted_11 = {
  key: 6,
  class: "order-status"
};
const _hoisted_12 = {
  class: "description"
};
const _hoisted_13 = {
  class: "description"
};
const _hoisted_14 = {
  key: 7,
  class: "order-status"
};
const _hoisted_15 = {
  key: 8,
  class: "order-status"
};
import { computed, onMounted, ref, watch } from 'vue';
import QRCode from 'qrcode';
export default {
  __name: 'order-status',
  props: {
    orderDetails: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const qrcodeCanvas = ref(null);
    const isWaitingPayment = computed(() => props.orderDetails.orderStatus === 1);
    const isCooking = computed(() => props.orderDetails.orderStatus === 2);
    const isOrderCompleted = computed(() => {
      const oneDayInMillis = 6 * 60 * 60 * 1000; // 6小时
      const payTime = new Date(props.orderDetails.payTime).getTime();
      const currentTime = new Date().getTime();
      return props.orderDetails.orderStatus === 6 && currentTime - payTime > oneDayInMillis;
    });
    const isOrderCanceling = computed(() => props.orderDetails.orderStatus === 9);
    const isOrderCancelled = computed(() => props.orderDetails.orderStatus === 7 || props.orderDetails.orderStatus === 8);
    const isPickupReady = computed(() => {
      return props.orderDetails.pickupType === 1 && props.orderDetails.orderStatus === 3 || props.orderDetails.pickupType === 1 && props.orderDetails.orderStatus === 6 && !isOrderCompleted.value;
    });
    const isCabinetPickupReady = computed(() => props.orderDetails.pickupType === 2 && props.orderDetails.orderStatus === 6);
    const isWaitingForCabinetAssignment = computed(() => {
      return props.orderDetails.pickupType === 2 && props.orderDetails.orderStatus === 2 && props.orderDetails.orderType === 2 && props.orderDetails.posOrderStatus !== 0 || props.orderDetails.pickupType === 2 && props.orderDetails.orderType === 1 && props.orderDetails.orderStatus === 2 || props.orderDetails.pickupType === 2 && props.orderDetails.orderStatus === 3 && props.orderDetails.orderStatus !== 2;
    });
    const diningCabinetNumber = computed(() => {
      try {
        return JSON.parse(props.orderDetails.diningCabinetNumber)[0];
      } catch (e) {
        return '未知';
      }
    });
    watch(isCabinetPickupReady, newVal => {
      if (newVal) {
        generateQRCode();
      }
    });
    const generateQRCode = () => {
      const pickupJson = {
        "type": "TAKE_BY_CODE",
        "code": props.orderDetails.diningCabinetPickupCode
      };
      const qrCodeData = JSON.stringify(pickupJson);
      QRCode.toCanvas(qrcodeCanvas.value, qrCodeData, {
        width: 200,
        height: 200,
        margin: 0,
        padding: 0
      }, error => {
        if (error) console.error(error);
      });
    };
    onMounted(() => {
      if (isCabinetPickupReady.value) {
        generateQRCode();
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [isWaitingPayment.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [_createElementVNode("div", null, [_createElementVNode("img", {
        class: "icon",
        src: _imports_0,
        alt: "支付"
      }), _createElementVNode("span", {
        style: {
          "font-size": "16px",
          "font-weight": "bold",
          "color": "red"
        }
      }, "等待支付")], -1), _createElementVNode("p", {
        class: "description"
      }, "订单尚未付款，请尽快支付", -1)]))) : isOrderCompleted.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [_createElementVNode("h3", null, "订单已完成", -1), _createElementVNode("p", {
        class: "description"
      }, "感谢您的光临，期待您的再次光临", -1)]))) : isOrderCanceling.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [_createElementVNode("h3", {
        style: {
          "color": "red"
        }
      }, "订单取消中", -1), _createElementVNode("p", {
        class: "description"
      }, "订单正在取消中，请稍候", -1)]))) : isOrderCancelled.value ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("h3", {
        style: {
          "color": "red"
        }
      }, "订单已取消", -1)), _createElementVNode("p", _hoisted_6, _toDisplayString(__props.orderDetails.refundReason || '其他原因'), 1)])) : isPickupReady.value ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString(__props.orderDetails.pickupNumber), 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
        class: "pickup-tag"
      }, "取餐码", -1))]), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "description"
      }, [_createElementVNode("p", {
        style: {
          "margin": "0",
          "padding": "0"
        }
      }, "请至门店柜台凭取餐号取餐"), _createElementVNode("p", {
        style: {
          "margin": "0",
          "padding": "0"
        }
      }, "请留意大屏叫号")], -1))])) : isCooking.value ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[6] || (_cache[6] = [_createElementVNode("p", {
        class: "code"
      }, "出餐中", -1), _createElementVNode("p", {
        class: "description"
      }, "请稍候，您的餐品正在准备中...", -1)]))) : isCabinetPickupReady.value ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_cache[7] || (_cache[7] = _createElementVNode("h3", null, "请至门店取餐柜凭二维码取餐", -1)), _cache[8] || (_cache[8] = _createElementVNode("p", {
        class: "description"
      }, "冰激凌等冷饮，请凭取餐柜内餐牌至柜台领取", -1)), _createElementVNode("canvas", {
        ref_key: "qrcodeCanvas",
        ref: qrcodeCanvas,
        class: "qrcode-canvas"
      }, null, 512), _createElementVNode("p", _hoisted_12, "取餐柜取餐码：" + _toDisplayString(__props.orderDetails.diningCabinetPickupCode), 1), _createElementVNode("p", _hoisted_13, "柜号：" + _toDisplayString(diningCabinetNumber.value), 1)])) : isWaitingForCabinetAssignment.value ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[9] || (_cache[9] = [_createElementVNode("h3", null, "正在等待分配取餐柜", -1), _createElementVNode("p", {
        class: "description"
      }, "餐品放入取餐柜后将生成取餐柜二维码", -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[10] || (_cache[10] = [_createElementVNode("h3", null, "订单其他状态", -1), _createElementVNode("p", {
        class: "description"
      }, "请咨询客服", -1)])))]);
    };
  }
};