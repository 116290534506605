import { defineStore } from 'pinia'
import { queryNearList } from '@/api/store'

export const useStoreStore = defineStore('store', {
  state: () => ({
    stores: []
  }),
  actions: {
    async fetchStores(city, store_name, lng, lat) {
      try {
        const response = await queryNearList({ city, store_name: store_name, lng, lat })
        this.stores = response.data.body
      } catch (error) {
        console.error('Error fetching stores:', error)
      }
    }
  }
})