import { defineStore } from 'pinia'
import { getProductByCode, getDisabledList } from '@/api/product'

export const useProductStore = defineStore('product', {
  state: () => ({
    product: null
  }),
  actions: {
    async getDisabledList() {
      try {
        const restaurant = JSON.parse(localStorage.getItem('restaurant')) || {};
        const storeCode = restaurant.storeCode;
        const { data } = await getDisabledList({ storeCode });
        return data?.body?.singleSkuCodes || [];
      }catch (e) {
        console.error(e);
        return [];
      }
    },
    async fetchProductByCode(code) {
      try {
        const response = await getProductByCode({ cardNo: code })
        const { data } = response;

        const disabledList = await this.getDisabledList();

        data.products.forEach(product => {

          // 添加唯一id
          product._local_id = `${product.id}_${product.seq}`;

          // 禁用商品添加标识
          product.productDetail.rounds.forEach(round => {
            round.items.forEach(item => {
              item.disabled = disabledList.includes(item.itemSkuCode)
            });
            round.saleQty = round.saleQty || round.items.length;
          })
          product.productSelectDetail.rounds.forEach(round => {
            round.items.forEach(item => {
              item.disabled = disabledList.includes(item.itemSkuCode)
            });
            round.saleQty = round.saleQty || round.items.length;
          })

          product.productDetail.rounds = product.productDetail.rounds.filter(round => round.items.length > 0);
          product.productSelectDetail.rounds = product.productSelectDetail.rounds.filter(round => round.items.length > 0);
        })
        console.log(data)
        this.product = data
      } catch (error) {
        throw error
      }
    }
  }
})
