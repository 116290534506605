import { createRouter, createWebHistory } from 'vue-router'
import RestaurantSelection from '@/views/RestaurantSelection'
import MealSelection from '@/views/MealSelection'
import WaitingCode from '@/views/WaitingCode'
import PickupConfirmation from '@/views/PickupConfirmation'
import CitySelector from '@/views/CitySelector'
import OrderConfirmation from '@/views/OrderConfirmation'
import Orders from '@/views/Orders'
import OrderDetails from '@/views/OrderDetails'
import LoadingPage from '@/views/LoadingPage'
import ErrorPage from '@/views/ErrorPage'

const routes = [
  {
    path: '/',
    name: 'Loading',
    component: LoadingPage
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage
  },
  {
    path: '/restaurants',
    name: 'RestaurantSelection',
    component: RestaurantSelection
  },
  {
    path: '/confirm',
    name: 'OrderConfirmation',
    component: OrderConfirmation
  },
  {
    path: '/meals',
    name: 'MealSelection',
    component: MealSelection
  },
  {
    path: '/wait',
    name: 'WaitingCode',
    component: WaitingCode
  },
  {
    path: '/pickup',
    name: 'PickupConfirmation',
    component: PickupConfirmation
  },
  {
    path: '/cities',
    name: 'CitySelector',
    component: CitySelector
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/details/:id',
    name: 'OrderDetails',
    component: OrderDetails
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
