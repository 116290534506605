export const cities = [{
    name: "A",
    data: [{
        citycode: "0372",
        adcode: "410500",
        name: "安阳市",
        lng: "114.39248",
        lat: "36.098779",
        sname: "安阳",
        pinyin: "Anyang"
    }, {
        citycode: "0483",
        adcode: "152900",
        name: "阿拉善盟",
        lng: "105.729135",
        lat: "38.851554",
        sname: "阿拉善盟",
        pinyin: "Alashanmeng"
    }, {
        citycode: "0906",
        adcode: "654300",
        name: "阿勒泰地区",
        lng: "88.141253",
        lat: "47.844924",
        sname: "阿勒泰地区",
        pinyin: "Aletaidiqu"
    }, {
        citycode: "1997",
        adcode: "659002",
        name: "阿拉尔市",
        lng: "81.280532",
        lat: "40.547205",
        sname: "阿拉尔",
        pinyin: "Alaer"
    }, {
        citycode: "0997",
        adcode: "652900",
        name: "阿克苏地区",
        lng: "80.265068",
        lat: "41.170712",
        sname: "阿克苏地区",
        pinyin: "Akesudiqu"
    }, {
        citycode: "0412",
        adcode: "210300",
        name: "鞍山市",
        lng: "122.994183",
        lat: "41.108239",
        sname: "鞍山",
        pinyin: "Anshan"
    }, {
        citycode: "0915",
        adcode: "610900",
        name: "安康市",
        lng: "109.029017",
        lat: "32.685435",
        sname: "安康",
        pinyin: "Ankang"
    }, {
        citycode: "0853",
        adcode: "520400",
        name: "安顺市",
        lng: "105.9476",
        lat: "26.253103",
        sname: "安顺",
        pinyin: "Anshun"
    }, {
        citycode: "0556",
        adcode: "340800",
        name: "安庆市",
        lng: "117.115349",
        lat: "30.531828",
        sname: "安庆",
        pinyin: "Anqing"
    }, {
        citycode: "0837",
        adcode: "513200",
        name: "阿坝藏族羌族自治州",
        lng: "102.224504",
        lat: "31.899427",
        sname: "阿坝藏族羌族自治州",
        pinyin: "Abacangzuqiangzuzizhizhou"
    }, {
        citycode: "0897",
        adcode: "542500",
        name: "阿里地区",
        lng: "80.105786",
        lat: "32.500987",
        sname: "阿里地区",
        pinyin: "Alidiqu"
    }]
}, {
    name: "B",
    data: [{
        citycode: "0472",
        adcode: "150200",
        name: "包头市",
        lng: "109.95315",
        lat: "40.621327",
        sname: "包头",
        pinyin: "Baotou"
    }, {
        citycode: "0478",
        adcode: "150800",
        name: "巴彦淖尔市",
        lng: "107.387767",
        lat: "40.742987",
        sname: "巴彦淖尔",
        pinyin: "Bayanneer"
    }, {
        citycode: "1906",
        adcode: "659005",
        name: "北屯市",
        lng: "87.834419",
        lat: "47.326733",
        sname: "北屯",
        pinyin: "Beitun"
    }, {
        citycode: "0909",
        adcode: "652700",
        name: "博尔塔拉蒙古自治州",
        lng: "82.066363",
        lat: "44.906039",
        sname: "博尔塔拉蒙古自治州",
        pinyin: "Boertalamengguzizhizhou"
    }, {
        citycode: "0996",
        adcode: "652800",
        name: "巴音郭楞蒙古自治州",
        lng: "86.145298",
        lat: "41.764115",
        sname: "巴音郭楞蒙古自治州",
        pinyin: "Bayinguolengmengguzizhizhou"
    }, {
        citycode: "0414",
        adcode: "210500",
        name: "本溪市",
        lng: "123.684984",
        lat: "41.486834",
        sname: "本溪",
        pinyin: "Benxi"
    }, {
        citycode: "0543",
        adcode: "371600",
        name: "滨州市",
        lng: "117.970731",
        lat: "37.382687",
        sname: "滨州",
        pinyin: "Binzhou"
    }, {
        citycode: "0917",
        adcode: "610300",
        name: "宝鸡市",
        lng: "107.237682",
        lat: "34.362862",
        sname: "宝鸡",
        pinyin: "Baoji"
    }, {
        citycode: "0857",
        adcode: "520500",
        name: "毕节市",
        lng: "105.291544",
        lat: "27.283615",
        sname: "毕节",
        pinyin: "Bijie"
    }, {
        citycode: "0558",
        adcode: "341600",
        name: "亳州市",
        lng: "115.778588",
        lat: "33.846285",
        sname: "亳州",
        pinyin: "Bozhou"
    }, {
        citycode: "0552",
        adcode: "340300",
        name: "蚌埠市",
        lng: "117.388566",
        lat: "32.91682",
        sname: "蚌埠",
        pinyin: "Bangbu"
    }, {
        citycode: "0802",
        adcode: "469025",
        name: "白沙黎族自治县",
        lng: "109.4429",
        lat: "19.221641",
        sname: "白沙黎族自治县",
        pinyin: "Baishalizuzizhixian"
    }, {
        citycode: "0801",
        adcode: "469029",
        name: "保亭黎族苗族自治县",
        lng: "109.700279",
        lat: "18.640339",
        sname: "保亭黎族苗族自治县",
        pinyin: "Baotinglizumiaozuzizhixian"
    }, {
        citycode: "0776",
        adcode: "451000",
        name: "百色市",
        lng: "106.61869",
        lat: "23.90307",
        sname: "百色",
        pinyin: "Baise"
    }, {
        citycode: "0779",
        adcode: "450500",
        name: "北海市",
        lng: "109.120248",
        lat: "21.481305",
        sname: "北海",
        pinyin: "Beihai"
    }, {
        citycode: "0312",
        adcode: "130600",
        name: "保定市",
        lng: "115.464523",
        lat: "38.874476",
        sname: "保定",
        pinyin: "Baoding"
    }, {
        citycode: "0943",
        adcode: "620400",
        name: "白银市",
        lng: "104.138872",
        lat: "36.545123",
        sname: "白银",
        pinyin: "Baiyin"
    }, {
        citycode: "0827",
        adcode: "511900",
        name: "巴中市",
        lng: "106.747548",
        lat: "31.867853",
        sname: "巴中",
        pinyin: "Bazhong"
    }, {
        citycode: "0436",
        adcode: "220800",
        name: "白城市",
        lng: "122.838102",
        lat: "45.620131",
        sname: "白城",
        pinyin: "Baicheng"
    }, {
        citycode: "0439",
        adcode: "220600",
        name: "白山市",
        lng: "126.414274",
        lat: "41.944132",
        sname: "白山",
        pinyin: "Baishan"
    }, {
        citycode: "0875",
        adcode: "530500",
        name: "保山市",
        lng: "99.161489",
        lat: "25.112018",
        sname: "保山",
        pinyin: "Baoshan"
    }, {
        citycode: "010",
        adcode: "110100",
        name: "北京市",
        lng: "116.405285",
        lat: "39.904989",
        sname: "北京",
        pinyin: "Beijing"
    }]
}, {
    name: "C",
    data: [{
        citycode: "0768",
        adcode: "445100",
        name: "潮州市",
        lng: "116.621901",
        lat: "23.657662",
        sname: "潮州",
        pinyin: "Chaozhou"
    }, {
        citycode: "0476",
        adcode: "150400",
        name: "赤峰市",
        lng: "118.887613",
        lat: "42.256876",
        sname: "赤峰",
        pinyin: "Chifeng"
    }, {
        citycode: "0994",
        adcode: "652300",
        name: "昌吉回族自治州",
        lng: "87.308995",
        lat: "44.011044",
        sname: "昌吉回族自治州",
        pinyin: "Changjihuizuzizhizhou"
    }, {
        citycode: "0421",
        adcode: "211300",
        name: "朝阳市",
        lng: "120.488801",
        lat: "41.601855",
        sname: "朝阳",
        pinyin: "Chaoyang"
    }, {
        citycode: "0566",
        adcode: "341700",
        name: "池州市",
        lng: "117.495663",
        lat: "30.674264",
        sname: "池州",
        pinyin: "Chizhou"
    }, {
        citycode: "0550",
        adcode: "341100",
        name: "滁州市",
        lng: "118.333439",
        lat: "32.255904",
        sname: "滁州",
        pinyin: "Chuzhou"
    }, {
        citycode: "0736",
        adcode: "430700",
        name: "常德市",
        lng: "111.69905",
        lat: "29.031446",
        sname: "常德",
        pinyin: "Changde"
    }, {
        citycode: "0735",
        adcode: "431000",
        name: "郴州市",
        lng: "113.015517",
        lat: "25.770117",
        sname: "郴州",
        pinyin: "Chenzhou"
    }, {
        citycode: "0731",
        adcode: "430100",
        name: "长沙市",
        lng: "112.938882",
        lat: "28.228304",
        sname: "长沙",
        pinyin: "Changsha"
    }, {
        citycode: "0804",
        adcode: "469023",
        name: "澄迈县",
        lng: "110.007497",
        lat: "19.738885",
        sname: "澄迈县",
        pinyin: "Chengmaixian"
    }, {
        citycode: "0803",
        adcode: "469026",
        name: "昌江黎族自治县",
        lng: "109.055783",
        lat: "19.298139",
        sname: "昌江黎族自治县",
        pinyin: "Changjianglizuzizhixian"
    }, {
        citycode: "0519",
        adcode: "320400",
        name: "常州市",
        lng: "119.974092",
        lat: "31.811313",
        sname: "常州",
        pinyin: "Changzhou"
    }, {
        citycode: "1771",
        adcode: "451400",
        name: "崇左市",
        lng: "107.364973",
        lat: "22.377139",
        sname: "崇左",
        pinyin: "Chongzuo"
    }, {
        citycode: "0314",
        adcode: "130800",
        name: "承德市",
        lng: "117.962749",
        lat: "40.952942",
        sname: "承德",
        pinyin: "Chengde"
    }, {
        citycode: "0317",
        adcode: "130900",
        name: "沧州市",
        lng: "116.838715",
        lat: "38.304676",
        sname: "沧州",
        pinyin: "Cangzhou"
    }, {
        citycode: "028",
        adcode: "510100",
        name: "成都市",
        lng: "104.066301",
        lat: "30.572961",
        sname: "成都",
        pinyin: "Chengdu"
    }, {
        citycode: "0355",
        adcode: "140400",
        name: "长治市",
        lng: "113.117394",
        lat: "36.195142",
        sname: "长治",
        pinyin: "Changzhi"
    }, {
        citycode: "0895",
        adcode: "540300",
        name: "昌都市",
        lng: "97.170425",
        lat: "31.142879",
        sname: "昌都",
        pinyin: "Changdu"
    }, {
        citycode: "0431",
        adcode: "220100",
        name: "长春市",
        lng: "125.323643",
        lat: "43.816996",
        sname: "长春",
        pinyin: "Changchun"
    }, {
        citycode: "0878",
        adcode: "532300",
        name: "楚雄彝族自治州",
        lng: "101.528304",
        lat: "25.045678",
        sname: "楚雄彝族自治州",
        pinyin: "Chuxiongyizuzizhizhou"
    }]
}, {
    name: "D",
    data: [{
        citycode: "0769",
        adcode: "441900",
        name: "东莞市",
        lng: "113.751884",
        lat: "23.021016",
        sname: "东莞",
        pinyin: "Dongguan"
    }, {
        citycode: "0457",
        adcode: "232700",
        name: "大兴安岭地区",
        lng: "124.11786",
        lat: "50.41129",
        sname: "大兴安岭地区",
        pinyin: "Daxinganlingdiqu"
    }, {
        citycode: "0459",
        adcode: "230600",
        name: "大庆市",
        lng: "125.104078",
        lat: "46.589498",
        sname: "大庆",
        pinyin: "Daqing"
    }, {
        citycode: "0411",
        adcode: "210200",
        name: "大连市",
        lng: "121.614786",
        lat: "38.913962",
        sname: "大连",
        pinyin: "Dalian"
    }, {
        citycode: "0415",
        adcode: "210600",
        name: "丹东市",
        lng: "124.354419",
        lat: "40.000646",
        sname: "丹东",
        pinyin: "Dandong"
    }, {
        citycode: "0546",
        adcode: "370500",
        name: "东营市",
        lng: "118.674633",
        lat: "37.433992",
        sname: "东营",
        pinyin: "Dongying"
    }, {
        citycode: "0534",
        adcode: "371400",
        name: "德州市",
        lng: "116.359244",
        lat: "37.436492",
        sname: "德州",
        pinyin: "Dezhou"
    }, {
        citycode: "0807",
        adcode: "469007",
        name: "东方市",
        lng: "108.651829",
        lat: "19.095187",
        sname: "东方",
        pinyin: "Dongfang"
    }, {
        citycode: "0805",
        adcode: "460400",
        name: "儋州市",
        lng: "109.580812",
        lat: "19.520948",
        sname: "儋州",
        pinyin: "Danzhou"
    }, {
        citycode: "0806",
        adcode: "469021",
        name: "定安县",
        lng: "110.358001",
        lat: "19.681215",
        sname: "定安县",
        pinyin: "Dinganxian"
    }, {
        citycode: "0932",
        adcode: "621100",
        name: "定西市",
        lng: "104.592342",
        lat: "35.607947",
        sname: "定西",
        pinyin: "Dingxi"
    }, {
        citycode: "0838",
        adcode: "510600",
        name: "德阳市",
        lng: "104.397795",
        lat: "31.127449",
        sname: "德阳",
        pinyin: "Deyang"
    }, {
        citycode: "0818",
        adcode: "511700",
        name: "达州市",
        lng: "107.46778",
        lat: "31.209278",
        sname: "达州",
        pinyin: "Dazhou"
    }, {
        citycode: "0352",
        adcode: "140200",
        name: "大同市",
        lng: "113.366749",
        lat: "40.09711",
        sname: "大同",
        pinyin: "Datong"
    }, {
        citycode: "0872",
        adcode: "532900",
        name: "大理白族自治州",
        lng: "100.267608",
        lat: "25.606548",
        sname: "大理白族自治州",
        pinyin: "Dalibaizuzizhizhou"
    }, {
        citycode: "0887",
        adcode: "533400",
        name: "迪庆藏族自治州",
        lng: "99.70211",
        lat: "27.819149",
        sname: "迪庆藏族自治州",
        pinyin: "Diqingcangzuzizhizhou"
    }, {
        citycode: "0692",
        adcode: "533100",
        name: "德宏傣族景颇族自治州",
        lng: "98.585621",
        lat: "24.433146",
        sname: "德宏傣族景颇族自治州",
        pinyin: "Dehongdaizujingpozuzizhizhou"
    }]
}, {
    name: "E",
    data: [{
        citycode: "0477",
        adcode: "150600",
        name: "鄂尔多斯市",
        lng: "109.782473",
        lat: "39.608744",
        sname: "鄂尔多斯",
        pinyin: "Eerduosi"
    }, {
        citycode: "0718",
        adcode: "422800",
        name: "恩施土家族苗族自治州",
        lng: "109.488076",
        lat: "30.272104",
        sname: "恩施土家族苗族自治州",
        pinyin: "Enshitujiazumiaozuzizhizhou"
    }, {
        citycode: "0711",
        adcode: "420700",
        name: "鄂州市",
        lng: "114.894909",
        lat: "30.391461",
        sname: "鄂州",
        pinyin: "Ezhou"
    }]
}, {
    name: "F",
    data: [{
        citycode: "0757",
        adcode: "440600",
        name: "佛山市",
        lng: "113.121586",
        lat: "23.021351",
        sname: "佛山",
        pinyin: "Fushan"
    }, {
        citycode: "0418",
        adcode: "210900",
        name: "阜新市",
        lng: "121.670052",
        lat: "42.022028",
        sname: "阜新",
        pinyin: "Fuxin"
    }, {
        citycode: "0413",
        adcode: "210400",
        name: "抚顺市",
        lng: "123.957053",
        lat: "41.881311",
        sname: "抚顺",
        pinyin: "Fushun"
    }, {
        citycode: "1558",
        adcode: "341200",
        name: "阜阳市",
        lng: "115.814252",
        lat: "32.891032",
        sname: "阜阳",
        pinyin: "Fuyang"
    }, {
        citycode: "0591",
        adcode: "350100",
        name: "福州市",
        lng: "119.296411",
        lat: "26.074286",
        sname: "福州",
        pinyin: "Fuzhou"
    }, {
        citycode: "0770",
        adcode: "450600",
        name: "防城港市",
        lng: "108.35467",
        lat: "21.686732",
        sname: "防城港",
        pinyin: "Fangchenggang"
    }, {
        citycode: "0794",
        adcode: "361000",
        name: "抚州市",
        lng: "116.358054",
        lat: "27.948979",
        sname: "抚州",
        pinyin: "Fuzhou"
    }]
}, {
    name: "G",
    data: [{
        citycode: "020",
        adcode: "440100",
        name: "广州市",
        lng: "113.264499",
        lat: "23.130061",
        sname: "广州",
        pinyin: "Guangzhou"
    }, {
        citycode: "0851",
        adcode: "520100",
        name: "贵阳市",
        lng: "106.628201",
        lat: "26.646694",
        sname: "贵阳",
        pinyin: "Guiyang"
    }, {
        citycode: "0975",
        adcode: "632600",
        name: "果洛藏族自治州",
        lng: "100.245161",
        lat: "34.472179",
        sname: "果洛藏族自治州",
        pinyin: "Guoluocangzuzizhizhou"
    }, {
        citycode: "0773",
        adcode: "450300",
        name: "桂林市",
        lng: "110.179752",
        lat: "25.235615",
        sname: "桂林",
        pinyin: "Guilin"
    }, {
        citycode: "1755",
        adcode: "450800",
        name: "贵港市",
        lng: "109.598903",
        lat: "23.11182",
        sname: "贵港",
        pinyin: "Guigang"
    }, {
        citycode: "0954",
        adcode: "640400",
        name: "固原市",
        lng: "106.24267",
        lat: "36.01628",
        sname: "固原",
        pinyin: "Guyuan"
    }, {
        citycode: "0941",
        adcode: "623000",
        name: "甘南藏族自治州",
        lng: "102.911736",
        lat: "34.983266",
        sname: "甘南藏族自治州",
        pinyin: "Gannancangzuzizhizhou"
    }, {
        citycode: "0839",
        adcode: "510800",
        name: "广元市",
        lng: "105.844004",
        lat: "32.435774",
        sname: "广元",
        pinyin: "Guangyuan"
    }, {
        citycode: "0826",
        adcode: "511600",
        name: "广安市",
        lng: "106.632647",
        lat: "30.456354",
        sname: "广安",
        pinyin: "Guangan"
    }, {
        citycode: "0836",
        adcode: "513300",
        name: "甘孜藏族自治州",
        lng: "101.96231",
        lat: "30.04952",
        sname: "甘孜藏族自治州",
        pinyin: "Ganzicangzuzizhizhou"
    }, {
        citycode: "0797",
        adcode: "360700",
        name: "赣州市",
        lng: "114.933494",
        lat: "25.831139",
        sname: "赣州",
        pinyin: "Ganzhou"
    }]
}, {
    name: "H",
    data: [{
        citycode: "0392",
        adcode: "410600",
        name: "鹤壁市",
        lng: "114.297305",
        lat: "35.748329",
        sname: "鹤壁",
        pinyin: "Hebi"
    }, {
        citycode: "0752",
        adcode: "441300",
        name: "惠州市",
        lng: "114.415587",
        lat: "23.112368",
        sname: "惠州",
        pinyin: "Huizhou"
    }, {
        citycode: "0762",
        adcode: "441600",
        name: "河源市",
        lng: "114.700215",
        lat: "23.744276",
        sname: "河源",
        pinyin: "Heyuan"
    }, {
        citycode: "0470",
        adcode: "150700",
        name: "呼伦贝尔市",
        lng: "119.77845",
        lat: "49.166536",
        sname: "呼伦贝尔",
        pinyin: "Hulunbeier"
    }, {
        citycode: "0471",
        adcode: "150100",
        name: "呼和浩特市",
        lng: "111.748814",
        lat: "40.842127",
        sname: "呼和浩特",
        pinyin: "Huhehaote"
    }, {
        citycode: "0468",
        adcode: "230400",
        name: "鹤岗市",
        lng: "130.297687",
        lat: "47.350659",
        sname: "鹤岗",
        pinyin: "Hegang"
    }, {
        citycode: "0451",
        adcode: "230100",
        name: "哈尔滨市",
        lng: "126.53505",
        lat: "45.802981",
        sname: "哈尔滨",
        pinyin: "Haerbin"
    }, {
        citycode: "0456",
        adcode: "231100",
        name: "黑河市",
        lng: "127.528226",
        lat: "50.244887",
        sname: "黑河",
        pinyin: "Heihe"
    }, {
        citycode: "0903",
        adcode: "653200",
        name: "和田地区",
        lng: "79.921646",
        lat: "37.114406",
        sname: "和田地区",
        pinyin: "Hetiandiqu"
    }, {
        citycode: "0902",
        adcode: "650500",
        name: "哈密市",
        lng: "93.515053",
        lat: "42.819346",
        sname: "哈密",
        pinyin: "Hami"
    }, {
        citycode: "0992",
        adcode: "659010",
        name: "胡杨河市",
        lng: "84.827592",
        lat: "44.692894",
        sname: "胡杨河",
        pinyin: "Huyanghe"
    }, {
        citycode: "0713",
        adcode: "421100",
        name: "黄冈市",
        lng: "114.872425",
        lat: "30.453722",
        sname: "黄冈",
        pinyin: "Huanggang"
    }, {
        citycode: "0714",
        adcode: "420200",
        name: "黄石市",
        lng: "115.038999",
        lat: "30.201082",
        sname: "黄石",
        pinyin: "Huangshi"
    }, {
        citycode: "0429",
        adcode: "211400",
        name: "葫芦岛市",
        lng: "120.836783",
        lat: "40.710974",
        sname: "葫芦岛",
        pinyin: "Huludao"
    }, {
        citycode: "0530",
        adcode: "371700",
        name: "菏泽市",
        lng: "115.479646",
        lat: "35.234309",
        sname: "菏泽",
        pinyin: "Heze"
    }, {
        citycode: "0916",
        adcode: "610700",
        name: "汉中市",
        lng: "107.02319",
        lat: "33.066373",
        sname: "汉中",
        pinyin: "Hanzhong"
    }, {
        citycode: "0561",
        adcode: "340600",
        name: "淮北市",
        lng: "116.798362",
        lat: "33.956264",
        sname: "淮北",
        pinyin: "Huaibei"
    }, {
        citycode: "0559",
        adcode: "341000",
        name: "黄山市",
        lng: "118.337643",
        lat: "29.714886",
        sname: "黄山",
        pinyin: "Huangshan"
    }, {
        citycode: "0554",
        adcode: "340400",
        name: "淮南市",
        lng: "117.018603",
        lat: "32.585384",
        sname: "淮南",
        pinyin: "Huainan"
    }, {
        citycode: "0551",
        adcode: "340100",
        name: "合肥市",
        lng: "117.227267",
        lat: "31.820567",
        sname: "合肥",
        pinyin: "Hefei"
    }, {
        citycode: "0745",
        adcode: "431200",
        name: "怀化市",
        lng: "110.001598",
        lat: "27.569813",
        sname: "怀化",
        pinyin: "Huaihua"
    }, {
        citycode: "0734",
        adcode: "430400",
        name: "衡阳市",
        lng: "112.572016",
        lat: "26.894216",
        sname: "衡阳",
        pinyin: "Hengyang"
    }, {
        citycode: "0898",
        adcode: "460100",
        name: "海口市",
        lng: "110.198418",
        lat: "20.045805",
        sname: "海口",
        pinyin: "Haikou"
    }, {
        citycode: "0517",
        adcode: "320800",
        name: "淮安市",
        lng: "119.113166",
        lat: "33.551495",
        sname: "淮安",
        pinyin: "Huaian"
    }, {
        citycode: "0972",
        adcode: "630200",
        name: "海东市",
        lng: "102.41064",
        lat: "36.473448",
        sname: "海东",
        pinyin: "Haidong"
    }, {
        citycode: "0974",
        adcode: "632500",
        name: "海南藏族自治州",
        lng: "100.622647",
        lat: "36.296399",
        sname: "海南藏族自治州",
        pinyin: "Hainancangzuzizhizhou"
    }, {
        citycode: "0977",
        adcode: "632800",
        name: "海西蒙古族藏族自治州",
        lng: "97.33197",
        lat: "37.348114",
        sname: "海西蒙古族藏族自治州",
        pinyin: "Haiximengguzucangzuzizhizhou"
    }, {
        citycode: "0973",
        adcode: "632300",
        name: "黄南藏族自治州",
        lng: "102.015397",
        lat: "35.519317",
        sname: "黄南藏族自治州",
        pinyin: "Huangnancangzuzizhizhou"
    }, {
        citycode: "0970",
        adcode: "632200",
        name: "海北藏族自治州",
        lng: "100.900944",
        lat: "36.954612",
        sname: "海北藏族自治州",
        pinyin: "Haibeicangzuzizhizhou"
    }, {
        citycode: "1774",
        adcode: "451100",
        name: "贺州市",
        lng: "111.567216",
        lat: "24.404182",
        sname: "贺州",
        pinyin: "Hezhou"
    }, {
        citycode: "0778",
        adcode: "451200",
        name: "河池市",
        lng: "108.63639",
        lat: "24.48523",
        sname: "河池",
        pinyin: "Hechi"
    }, {
        citycode: "0571",
        adcode: "330100",
        name: "杭州市",
        lng: "120.210792",
        lat: "30.246026",
        sname: "杭州",
        pinyin: "Hangzhou"
    }, {
        citycode: "0572",
        adcode: "330500",
        name: "湖州市",
        lng: "120.086881",
        lat: "30.894178",
        sname: "湖州",
        pinyin: "Huzhou"
    }, {
        citycode: "0310",
        adcode: "130400",
        name: "邯郸市",
        lng: "114.53915",
        lat: "36.625849",
        sname: "邯郸",
        pinyin: "Handan"
    }, {
        citycode: "0318",
        adcode: "131100",
        name: "衡水市",
        lng: "115.668987",
        lat: "37.739367",
        sname: "衡水",
        pinyin: "Hengshui"
    }, {
        citycode: "0873",
        adcode: "532500",
        name: "红河哈尼族彝族自治州",
        lng: "103.374873",
        lat: "23.363129",
        sname: "红河哈尼族彝族自治州",
        pinyin: "Honghehanizuyizuzizhizhou"
    }]
}, {
    name: "J",
    data: [{
        citycode: "1391",
        adcode: "419001",
        name: "济源市",
        lng: "112.602347",
        lat: "35.069057",
        sname: "济源",
        pinyin: "Jiyuan"
    }, {
        citycode: "0391",
        adcode: "410800",
        name: "焦作市",
        lng: "113.241902",
        lat: "35.215726",
        sname: "焦作",
        pinyin: "Jiaozuo"
    }, {
        citycode: "0750",
        adcode: "440700",
        name: "江门市",
        lng: "113.081548",
        lat: "22.578948",
        sname: "江门",
        pinyin: "Jiangmen"
    }, {
        citycode: "0663",
        adcode: "445200",
        name: "揭阳市",
        lng: "116.372732",
        lat: "23.550968",
        sname: "揭阳",
        pinyin: "Jieyang"
    }, {
        citycode: "0454",
        adcode: "230800",
        name: "佳木斯市",
        lng: "130.318916",
        lat: "46.800002",
        sname: "佳木斯",
        pinyin: "Jiamusi"
    }, {
        citycode: "0467",
        adcode: "230300",
        name: "鸡西市",
        lng: "130.969385",
        lat: "45.295087",
        sname: "鸡西",
        pinyin: "Jixi"
    }, {
        citycode: "0724",
        adcode: "420800",
        name: "荆门市",
        lng: "112.199009",
        lat: "31.035445",
        sname: "荆门",
        pinyin: "Jingmen"
    }, {
        citycode: "0716",
        adcode: "421000",
        name: "荆州市",
        lng: "112.24143",
        lat: "30.336282",
        sname: "荆州",
        pinyin: "Jingzhou"
    }, {
        citycode: "0416",
        adcode: "210700",
        name: "锦州市",
        lng: "121.126859",
        lat: "41.096114",
        sname: "锦州",
        pinyin: "Jinzhou"
    }, {
        citycode: "0537",
        adcode: "370800",
        name: "济宁市",
        lng: "116.587116",
        lat: "35.415117",
        sname: "济宁",
        pinyin: "Jining"
    }, {
        citycode: "0531",
        adcode: "370100",
        name: "济南市",
        lng: "117.120128",
        lat: "36.652069",
        sname: "济南",
        pinyin: "Jinan"
    }, {
        citycode: "0573",
        adcode: "330400",
        name: "嘉兴市",
        lng: "120.755623",
        lat: "30.746814",
        sname: "嘉兴",
        pinyin: "Jiaxing"
    }, {
        citycode: "0579",
        adcode: "330700",
        name: "金华市",
        lng: "119.647265",
        lat: "29.079195",
        sname: "金华",
        pinyin: "Jinhua"
    }, {
        citycode: "1937",
        adcode: "620200",
        name: "嘉峪关市",
        lng: "98.2882",
        lat: "39.77325",
        sname: "嘉峪关",
        pinyin: "Jiayuguan"
    }, {
        citycode: "0937",
        adcode: "620900",
        name: "酒泉市",
        lng: "98.49432",
        lat: "39.733416",
        sname: "酒泉",
        pinyin: "Jiuquan"
    }, {
        citycode: "0935",
        adcode: "620300",
        name: "金昌市",
        lng: "102.187972",
        lat: "38.521468",
        sname: "金昌",
        pinyin: "Jinchang"
    }, {
        citycode: "0798",
        adcode: "360200",
        name: "景德镇市",
        lng: "117.184892",
        lat: "29.2744",
        sname: "景德镇",
        pinyin: "Jingdezhen"
    }, {
        citycode: "0796",
        adcode: "360800",
        name: "吉安市",
        lng: "114.96681",
        lat: "27.091243",
        sname: "吉安",
        pinyin: "Jian"
    }, {
        citycode: "0792",
        adcode: "360400",
        name: "九江市",
        lng: "115.95356",
        lat: "29.66116",
        sname: "九江",
        pinyin: "Jiujiang"
    }, {
        citycode: "0356",
        adcode: "140500",
        name: "晋城市",
        lng: "112.852022",
        lat: "35.491315",
        sname: "晋城",
        pinyin: "Jincheng"
    }, {
        citycode: "0354",
        adcode: "140700",
        name: "晋中市",
        lng: "112.752633",
        lat: "37.688006",
        sname: "晋中",
        pinyin: "Jinzhong"
    }, {
        citycode: "0432",
        adcode: "220200",
        name: "吉林市",
        lng: "126.549719",
        lat: "43.838132",
        sname: "吉林",
        pinyin: "Jilin"
    }]
}, {
    name: "K",
    data: [{
        citycode: "0378",
        adcode: "410200",
        name: "开封市",
        lng: "114.314278",
        lat: "34.798083",
        sname: "开封",
        pinyin: "Kaifeng"
    }, {
        citycode: "1999",
        adcode: "659008",
        name: "可克达拉市",
        lng: "80.994153",
        lat: "43.940381",
        sname: "可克达拉",
        pinyin: "Kekedala"
    }, {
        citycode: "1903",
        adcode: "659009",
        name: "昆玉市",
        lng: "79.270193",
        lat: "37.215372",
        sname: "昆玉",
        pinyin: "Kunyu"
    }, {
        citycode: "0998",
        adcode: "653100",
        name: "喀什地区",
        lng: "75.989746",
        lat: "39.470492",
        sname: "喀什地区",
        pinyin: "Kashidiqu"
    }, {
        citycode: "0908",
        adcode: "653000",
        name: "克孜勒苏柯尔克孜自治州",
        lng: "76.167327",
        lat: "39.714734",
        sname: "克孜勒苏柯尔克孜自治州",
        pinyin: "Kezilesukeerkezizizhizhou"
    }, {
        citycode: "0990",
        adcode: "650200",
        name: "克拉玛依市",
        lng: "84.889239",
        lat: "45.577712",
        sname: "克拉玛依",
        pinyin: "Kelamayi"
    }, {
        citycode: "0871",
        adcode: "530100",
        name: "昆明市",
        lng: "102.833669",
        lat: "24.88149",
        sname: "昆明",
        pinyin: "Kunming"
    }]
}, {
    name: "L",
    data: [{
        citycode: "0379",
        adcode: "410300",
        name: "洛阳市",
        lng: "112.453895",
        lat: "34.619702",
        sname: "洛阳",
        pinyin: "Luoyang"
    }, {
        citycode: "0395",
        adcode: "411100",
        name: "漯河市",
        lng: "114.0166",
        lat: "33.58038",
        sname: "漯河",
        pinyin: "Leihe"
    }, {
        citycode: "0419",
        adcode: "211000",
        name: "辽阳市",
        lng: "123.239669",
        lat: "41.267396",
        sname: "辽阳",
        pinyin: "Liaoyang"
    }, {
        citycode: "0635",
        adcode: "371500",
        name: "聊城市",
        lng: "115.985238",
        lat: "36.455857",
        sname: "聊城",
        pinyin: "Liaocheng"
    }, {
        citycode: "0539",
        adcode: "371300",
        name: "临沂市",
        lng: "118.356464",
        lat: "35.103771",
        sname: "临沂",
        pinyin: "Linyi"
    }, {
        citycode: "0858",
        adcode: "520200",
        name: "六盘水市",
        lng: "104.830357",
        lat: "26.592538",
        sname: "六盘水",
        pinyin: "Liupanshui"
    }, {
        citycode: "0564",
        adcode: "341500",
        name: "六安市",
        lng: "116.519729",
        lat: "31.735892",
        sname: "六安",
        pinyin: "Liuan"
    }, {
        citycode: "0597",
        adcode: "350800",
        name: "龙岩市",
        lng: "117.017362",
        lat: "25.075884",
        sname: "龙岩",
        pinyin: "Longyan"
    }, {
        citycode: "0738",
        adcode: "431300",
        name: "娄底市",
        lng: "111.994468",
        lat: "27.699838",
        sname: "娄底",
        pinyin: "Loudi"
    }, {
        citycode: "1896",
        adcode: "469024",
        name: "临高县",
        lng: "109.690508",
        lat: "19.912025",
        sname: "临高县",
        pinyin: "Lingaoxian"
    }, {
        citycode: "2802",
        adcode: "469027",
        name: "乐东黎族自治县",
        lng: "109.173384",
        lat: "18.750063",
        sname: "乐东黎族自治县",
        pinyin: "Ledonglizuzizhixian"
    }, {
        citycode: "0809",
        adcode: "469028",
        name: "陵水黎族自治县",
        lng: "110.037553",
        lat: "18.506045",
        sname: "陵水黎族自治县",
        pinyin: "Lingshuilizuzizhixian"
    }, {
        citycode: "0518",
        adcode: "320700",
        name: "连云港市",
        lng: "119.221487",
        lat: "34.596639",
        sname: "连云港",
        pinyin: "Lianyungang"
    }, {
        citycode: "1772",
        adcode: "451300",
        name: "来宾市",
        lng: "109.221243",
        lat: "23.750105",
        sname: "来宾",
        pinyin: "Laibin"
    }, {
        citycode: "0772",
        adcode: "450200",
        name: "柳州市",
        lng: "109.428071",
        lat: "24.326442",
        sname: "柳州",
        pinyin: "Liuzhou"
    }, {
        citycode: "0578",
        adcode: "331100",
        name: "丽水市",
        lng: "119.923249",
        lat: "28.467694",
        sname: "丽水",
        pinyin: "Lishui"
    }, {
        citycode: "0316",
        adcode: "131000",
        name: "廊坊市",
        lng: "116.683546",
        lat: "39.538304",
        sname: "廊坊",
        pinyin: "Langfang"
    }, {
        citycode: "0931",
        adcode: "620100",
        name: "兰州市",
        lng: "103.834228",
        lat: "36.060798",
        sname: "兰州",
        pinyin: "Lanzhou"
    }, {
        citycode: "2935",
        adcode: "621200",
        name: "陇南市",
        lng: "104.960296",
        lat: "33.370174",
        sname: "陇南",
        pinyin: "Longnan"
    }, {
        citycode: "0930",
        adcode: "622900",
        name: "临夏回族自治州",
        lng: "103.210386",
        lat: "35.601792",
        sname: "临夏回族自治州",
        pinyin: "Linxiahuizuzizhizhou"
    }, {
        citycode: "0833",
        adcode: "511100",
        name: "乐山市",
        lng: "103.766085",
        lat: "29.552275",
        sname: "乐山",
        pinyin: "Leshan"
    }, {
        citycode: "0830",
        adcode: "510500",
        name: "泸州市",
        lng: "105.441866",
        lat: "28.87098",
        sname: "泸州",
        pinyin: "Luzhou"
    }, {
        citycode: "0834",
        adcode: "513400",
        name: "凉山彝族自治州",
        lng: "102.267713",
        lat: "27.881396",
        sname: "凉山彝族自治州",
        pinyin: "Liangshanyizuzizhizhou"
    }, {
        citycode: "0357",
        adcode: "141000",
        name: "临汾市",
        lng: "111.51931",
        lat: "36.088581",
        sname: "临汾",
        pinyin: "Linfen"
    }, {
        citycode: "0358",
        adcode: "141100",
        name: "吕梁市",
        lng: "111.14454",
        lat: "37.518996",
        sname: "吕梁",
        pinyin: "Lvliang"
    }, {
        citycode: "0891",
        adcode: "540100",
        name: "拉萨市",
        lng: "91.171924",
        lat: "29.653491",
        sname: "拉萨",
        pinyin: "Lasa"
    }, {
        citycode: "0894",
        adcode: "540400",
        name: "林芝市",
        lng: "94.361436",
        lat: "29.64875",
        sname: "林芝",
        pinyin: "Linzhi"
    }, {
        citycode: "0437",
        adcode: "220400",
        name: "辽源市",
        lng: "125.144676",
        lat: "42.887961",
        sname: "辽源",
        pinyin: "Liaoyuan"
    }, {
        citycode: "0883",
        adcode: "530900",
        name: "临沧市",
        lng: "100.088837",
        lat: "23.884175",
        sname: "临沧",
        pinyin: "Lincang"
    }, {
        citycode: "0888",
        adcode: "530700",
        name: "丽江市",
        lng: "100.225936",
        lat: "26.855165",
        sname: "丽江",
        pinyin: "Lijiang"
    }]
}, {
    name: "M",
    data: [{
        citycode: "0668",
        adcode: "440900",
        name: "茂名市",
        lng: "110.925533",
        lat: "21.662728",
        sname: "茂名",
        pinyin: "Maoming"
    }, {
        citycode: "0753",
        adcode: "441400",
        name: "梅州市",
        lng: "116.122046",
        lat: "24.288832",
        sname: "梅州",
        pinyin: "Meizhou"
    }, {
        citycode: "0453",
        adcode: "231000",
        name: "牡丹江市",
        lng: "129.632928",
        lat: "44.551486",
        sname: "牡丹江",
        pinyin: "Mudanjiang"
    }, {
        citycode: "0555",
        adcode: "340500",
        name: "马鞍山市",
        lng: "118.50685",
        lat: "31.668765",
        sname: "马鞍山",
        pinyin: "Maanshan"
    }, {
        citycode: "0816",
        adcode: "510700",
        name: "绵阳市",
        lng: "104.679127",
        lat: "31.467673",
        sname: "绵阳",
        pinyin: "Mianyang"
    }, {
        citycode: "1833",
        adcode: "511400",
        name: "眉山市",
        lng: "103.848417",
        lat: "30.077113",
        sname: "眉山",
        pinyin: "Meishan"
    }]
}, {
    name: "N",
    data: [{
        citycode: "0377",
        adcode: "411300",
        name: "南阳市",
        lng: "112.584753",
        lat: "33.016102",
        sname: "南阳",
        pinyin: "Nanyang"
    }, {
        citycode: "0593",
        adcode: "350900",
        name: "宁德市",
        lng: "119.547729",
        lat: "26.666222",
        sname: "宁德",
        pinyin: "Ningde"
    }, {
        citycode: "0599",
        adcode: "350700",
        name: "南平市",
        lng: "118.081325",
        lat: "27.382829",
        sname: "南平",
        pinyin: "Nanping"
    }, {
        citycode: "0513",
        adcode: "320600",
        name: "南通市",
        lng: "120.894522",
        lat: "31.981269",
        sname: "南通",
        pinyin: "Nantong"
    }, {
        citycode: "025",
        adcode: "320100",
        name: "南京市",
        lng: "118.796624",
        lat: "32.059344",
        sname: "南京",
        pinyin: "Nanjing"
    }, {
        citycode: "0771",
        adcode: "450100",
        name: "南宁市",
        lng: "108.366407",
        lat: "22.8177",
        sname: "南宁",
        pinyin: "Nanning"
    }, {
        citycode: "0574",
        adcode: "330200",
        name: "宁波市",
        lng: "121.62454",
        lat: "29.860258",
        sname: "宁波",
        pinyin: "Ningbo"
    }, {
        citycode: "0817",
        adcode: "511300",
        name: "南充市",
        lng: "106.110565",
        lat: "30.837235",
        sname: "南充",
        pinyin: "Nanchong"
    }, {
        citycode: "1832",
        adcode: "511000",
        name: "内江市",
        lng: "105.057992",
        lat: "29.58021",
        sname: "内江",
        pinyin: "Najiang"
    }, {
        citycode: "0791",
        adcode: "360100",
        name: "南昌市",
        lng: "115.857972",
        lat: "28.682976",
        sname: "南昌",
        pinyin: "Nanchang"
    }, {
        citycode: "0896",
        adcode: "540600",
        name: "那曲市",
        lng: "92.05151",
        lat: "31.477905",
        sname: "那曲",
        pinyin: "Naqu"
    }, {
        citycode: "0886",
        adcode: "533300",
        name: "怒江傈僳族自治州",
        lng: "98.8566",
        lat: "25.817555",
        sname: "怒江傈僳族自治州",
        pinyin: "Nujianglisuzuzizhizhou"
    }]
}, {
    name: "P",
    data: [{
        citycode: "0393",
        adcode: "410900",
        name: "濮阳市",
        lng: "115.029246",
        lat: "35.762731",
        sname: "濮阳",
        pinyin: "Puyang"
    }, {
        citycode: "0375",
        adcode: "410400",
        name: "平顶山市",
        lng: "113.192595",
        lat: "33.766554",
        sname: "平顶山",
        pinyin: "Pingdingshan"
    }, {
        citycode: "0427",
        adcode: "211100",
        name: "盘锦市",
        lng: "122.170729",
        lat: "40.71956",
        sname: "盘锦",
        pinyin: "Panjin"
    }, {
        citycode: "0594",
        adcode: "350300",
        name: "莆田市",
        lng: "119.007662",
        lat: "25.454202",
        sname: "莆田",
        pinyin: "Putian"
    }, {
        citycode: "0933",
        adcode: "620800",
        name: "平凉市",
        lng: "106.664913",
        lat: "35.542417",
        sname: "平凉",
        pinyin: "Pingliang"
    }, {
        citycode: "0812",
        adcode: "510400",
        name: "攀枝花市",
        lng: "101.71846",
        lat: "26.582417",
        sname: "攀枝花",
        pinyin: "Panzhihua"
    }, {
        citycode: "0799",
        adcode: "360300",
        name: "萍乡市",
        lng: "113.887147",
        lat: "27.658721",
        sname: "萍乡",
        pinyin: "Pingxiang"
    }, {
        citycode: "0879",
        adcode: "530800",
        name: "普洱市",
        lng: "100.966011",
        lat: "22.825229",
        sname: "普洱",
        pinyin: "Puer"
    }]
}, {
    name: "Q",
    data: [{
        citycode: "0763",
        adcode: "441800",
        name: "清远市",
        lng: "113.056098",
        lat: "23.682064",
        sname: "清远",
        pinyin: "Qingyuan"
    }, {
        citycode: "0464",
        adcode: "230900",
        name: "七台河市",
        lng: "131.003015",
        lat: "45.771178",
        sname: "七台河",
        pinyin: "Qitaihe"
    }, {
        citycode: "0452",
        adcode: "230200",
        name: "齐齐哈尔市",
        lng: "123.918193",
        lat: "47.354892",
        sname: "齐齐哈尔",
        pinyin: "Qiqihaer"
    }, {
        citycode: "2728",
        adcode: "429005",
        name: "潜江市",
        lng: "112.900279",
        lat: "30.401954",
        sname: "潜江",
        pinyin: "Qianjiang"
    }, {
        citycode: "0532",
        adcode: "370200",
        name: "青岛市",
        lng: "120.382665",
        lat: "36.066938",
        sname: "青岛",
        pinyin: "Qingdao"
    }, {
        citycode: "0854",
        adcode: "522700",
        name: "黔南布依族苗族自治州",
        lng: "107.522303",
        lat: "26.253136",
        sname: "黔南布依族苗族自治州",
        pinyin: "Qiannanbuyizumiaozuzizhizhou"
    }, {
        citycode: "0859",
        adcode: "522300",
        name: "黔西南布依族苗族自治州",
        lng: "104.906419",
        lat: "25.087733",
        sname: "黔西南布依族苗族自治州",
        pinyin: "Qianxinanbuyizumiaozuzizhizhou"
    }, {
        citycode: "0855",
        adcode: "522600",
        name: "黔东南苗族侗族自治州",
        lng: "107.982838",
        lat: "26.583759",
        sname: "黔东南苗族侗族自治州",
        pinyin: "Qiandongnanmiaozudongzuzizhizhou"
    }, {
        citycode: "0595",
        adcode: "350500",
        name: "泉州市",
        lng: "118.675724",
        lat: "24.874452",
        sname: "泉州",
        pinyin: "Quanzhou"
    }, {
        citycode: "1899",
        adcode: "469030",
        name: "琼中黎族苗族自治县",
        lng: "109.838423",
        lat: "19.03327",
        sname: "琼中黎族苗族自治县",
        pinyin: "Qiongzhonglizumiaozuzizhixian"
    }, {
        citycode: "1894",
        adcode: "469002",
        name: "琼海市",
        lng: "110.474524",
        lat: "19.259112",
        sname: "琼海",
        pinyin: "Qionghai"
    }, {
        citycode: "0777",
        adcode: "450700",
        name: "钦州市",
        lng: "108.654355",
        lat: "21.980894",
        sname: "钦州",
        pinyin: "Qinzhou"
    }, {
        citycode: "0570",
        adcode: "330800",
        name: "衢州市",
        lng: "118.859307",
        lat: "28.970229",
        sname: "衢州",
        pinyin: "Quzhou"
    }, {
        citycode: "0335",
        adcode: "130300",
        name: "秦皇岛市",
        lng: "119.52022",
        lat: "39.888243",
        sname: "秦皇岛",
        pinyin: "Qinhuangdao"
    }, {
        citycode: "0934",
        adcode: "621000",
        name: "庆阳市",
        lng: "107.643433",
        lat: "35.709459",
        sname: "庆阳",
        pinyin: "Qingyang"
    }, {
        citycode: "0874",
        adcode: "530300",
        name: "曲靖市",
        lng: "103.796288",
        lat: "25.490866",
        sname: "曲靖",
        pinyin: "Qujing"
    }]
}, {
    name: "R",
    data: [{
        citycode: "0633",
        adcode: "371100",
        name: "日照市",
        lng: "119.52685",
        lat: "35.416912",
        sname: "日照",
        pinyin: "Rizhao"
    }, {
        citycode: "0892",
        adcode: "540200",
        name: "日喀则市",
        lng: "88.880423",
        lat: "29.266838",
        sname: "日喀则",
        pinyin: "Rikaze"
    }]
}, {
    name: "S",
    data: [{
        citycode: "0398",
        adcode: "411200",
        name: "三门峡市",
        lng: "111.200482",
        lat: "34.773196",
        sname: "三门峡",
        pinyin: "Sanmenxia"
    }, {
        citycode: "0370",
        adcode: "411400",
        name: "商丘市",
        lng: "115.656358",
        lat: "34.415165",
        sname: "商丘",
        pinyin: "Shangqiu"
    }, {
        citycode: "0754",
        adcode: "440500",
        name: "汕头市",
        lng: "116.681956",
        lat: "23.354152",
        sname: "汕头",
        pinyin: "Shantou"
    }, {
        citycode: "0755",
        adcode: "440300",
        name: "深圳市",
        lng: "114.057939",
        lat: "22.543527",
        sname: "深圳",
        pinyin: "Shenzhen"
    }, {
        citycode: "0660",
        adcode: "441500",
        name: "汕尾市",
        lng: "115.375557",
        lat: "22.787204",
        sname: "汕尾",
        pinyin: "Shanwei"
    }, {
        citycode: "0751",
        adcode: "440200",
        name: "韶关市",
        lng: "113.597324",
        lat: "24.810977",
        sname: "韶关",
        pinyin: "Shaoguan"
    }, {
        citycode: "0469",
        adcode: "230500",
        name: "双鸭山市",
        lng: "131.141563",
        lat: "46.676157",
        sname: "双鸭山",
        pinyin: "Shuangyashan"
    }, {
        citycode: "0455",
        adcode: "231200",
        name: "绥化市",
        lng: "126.968714",
        lat: "46.654147",
        sname: "绥化",
        pinyin: "Suihua"
    }, {
        citycode: "1909",
        adcode: "659007",
        name: "双河市",
        lng: "82.35365587",
        lat: "44.84052409",
        sname: "双河",
        pinyin: "Shuanghe"
    }, {
        citycode: "0993",
        adcode: "659001",
        name: "石河子市",
        lng: "86.080397",
        lat: "44.305368",
        sname: "石河子",
        pinyin: "Shihezi"
    }, {
        citycode: "0719",
        adcode: "420300",
        name: "十堰市",
        lng: "110.798921",
        lat: "32.629057",
        sname: "十堰",
        pinyin: "Shiyan"
    }, {
        citycode: "1719",
        adcode: "429021",
        name: "神农架林区",
        lng: "110.675879",
        lat: "31.745103",
        sname: "神农架林区",
        pinyin: "Shennongjialinqu"
    }, {
        citycode: "0722",
        adcode: "421300",
        name: "随州市",
        lng: "113.382324",
        lat: "31.690275",
        sname: "随州",
        pinyin: "Suizhou"
    }, {
        citycode: "024",
        adcode: "210100",
        name: "沈阳市",
        lng: "123.464675",
        lat: "41.677576",
        sname: "沈阳",
        pinyin: "Shenyang"
    }, {
        citycode: "0914",
        adcode: "611000",
        name: "商洛市",
        lng: "109.918646",
        lat: "33.873358",
        sname: "商洛",
        pinyin: "Shangluo"
    }, {
        citycode: "021",
        adcode: "310100",
        name: "上海市",
        lng: "121.472644",
        lat: "31.231706",
        sname: "上海",
        pinyin: "Shanghai"
    }, {
        citycode: "0557",
        adcode: "341300",
        name: "宿州市",
        lng: "116.96419",
        lat: "33.647726",
        sname: "宿州",
        pinyin: "Suzhou"
    }, {
        citycode: "0598",
        adcode: "350400",
        name: "三明市",
        lng: "117.638919",
        lat: "26.263455",
        sname: "三明",
        pinyin: "Sanming"
    }, {
        citycode: "0739",
        adcode: "430500",
        name: "邵阳市",
        lng: "111.467855",
        lat: "27.239528",
        sname: "邵阳",
        pinyin: "Shaoyang"
    }, {
        citycode: "2898",
        adcode: "460300",
        name: "三沙市",
        lng: "112.338649",
        lat: "16.831004",
        sname: "三沙",
        pinyin: "Sansha"
    }, {
        citycode: "0899",
        adcode: "460200",
        name: "三亚市",
        lng: "109.511709",
        lat: "18.252865",
        sname: "三亚",
        pinyin: "Sanya"
    }, {
        citycode: "0527",
        adcode: "321300",
        name: "宿迁市",
        lng: "118.275228",
        lat: "33.963186",
        sname: "宿迁",
        pinyin: "Suqian"
    }, {
        citycode: "0512",
        adcode: "320500",
        name: "苏州市",
        lng: "120.585294",
        lat: "31.299758",
        sname: "苏州",
        pinyin: "Suzhou"
    }, {
        citycode: "0952",
        adcode: "640200",
        name: "石嘴山市",
        lng: "106.382792",
        lat: "38.984632",
        sname: "石嘴山",
        pinyin: "Shizuishan"
    }, {
        citycode: "0575",
        adcode: "330600",
        name: "绍兴市",
        lng: "120.582886",
        lat: "30.051549",
        sname: "绍兴",
        pinyin: "Shaoxing"
    }, {
        citycode: "0311",
        adcode: "130100",
        name: "石家庄市",
        lng: "114.514976",
        lat: "38.042007",
        sname: "石家庄",
        pinyin: "Shijiazhuang"
    }, {
        citycode: "0825",
        adcode: "510900",
        name: "遂宁市",
        lng: "105.592602",
        lat: "30.53268",
        sname: "遂宁",
        pinyin: "Suining"
    }, {
        citycode: "0793",
        adcode: "361100",
        name: "上饶市",
        lng: "117.943064",
        lat: "28.45513",
        sname: "上饶",
        pinyin: "Shangrao"
    }, {
        citycode: "0349",
        adcode: "140600",
        name: "朔州市",
        lng: "112.432906",
        lat: "39.331734",
        sname: "朔州",
        pinyin: "Shuozhou"
    }, {
        citycode: "0893",
        adcode: "540500",
        name: "山南市",
        lng: "91.771426",
        lat: "29.237722",
        sname: "山南",
        pinyin: "Shannan"
    }, {
        citycode: "0438",
        adcode: "220700",
        name: "松原市",
        lng: "124.825321",
        lat: "45.14191",
        sname: "松原",
        pinyin: "Songyuan"
    }, {
        citycode: "0434",
        adcode: "220300",
        name: "四平市",
        lng: "124.350599",
        lat: "43.166764",
        sname: "四平",
        pinyin: "Siping"
    }]
}, {
    name: "T",
    data: [{
        citycode: "0475",
        adcode: "150500",
        name: "通辽市",
        lng: "122.243309",
        lat: "43.653566",
        sname: "通辽",
        pinyin: "Tongliao"
    }, {
        citycode: "1996",
        adcode: "659006",
        name: "铁门关市",
        lng: "85.670291",
        lat: "41.862997",
        sname: "铁门关",
        pinyin: "Tiemenguan"
    }, {
        citycode: "0901",
        adcode: "654200",
        name: "塔城地区",
        lng: "82.980316",
        lat: "46.745364",
        sname: "塔城地区",
        pinyin: "Tachengdiqu"
    }, {
        citycode: "0995",
        adcode: "650400",
        name: "吐鲁番市",
        lng: "89.190374",
        lat: "42.950736",
        sname: "吐鲁番",
        pinyin: "Tulufan"
    }, {
        citycode: "1998",
        adcode: "659003",
        name: "图木舒克市",
        lng: "79.074965",
        lat: "39.867776",
        sname: "图木舒克",
        pinyin: "Tumushuke"
    }, {
        citycode: "1728",
        adcode: "429006",
        name: "天门市",
        lng: "113.166545",
        lat: "30.663706",
        sname: "天门",
        pinyin: "Tianmen"
    }, {
        citycode: "0410",
        adcode: "211200",
        name: "铁岭市",
        lng: "123.726008",
        lat: "42.223709",
        sname: "铁岭",
        pinyin: "Tieling"
    }, {
        citycode: "0538",
        adcode: "370900",
        name: "泰安市",
        lng: "117.086963",
        lat: "36.201784",
        sname: "泰安",
        pinyin: "Taian"
    }, {
        citycode: "0919",
        adcode: "610200",
        name: "铜川市",
        lng: "108.945116",
        lat: "34.897133",
        sname: "铜川",
        pinyin: "Tongchuan"
    }, {
        citycode: "0856",
        adcode: "520600",
        name: "铜仁市",
        lng: "109.189528",
        lat: "27.731555",
        sname: "铜仁",
        pinyin: "Tongren"
    }, {
        citycode: "0562",
        adcode: "340700",
        name: "铜陵市",
        lng: "117.811298",
        lat: "30.945214",
        sname: "铜陵",
        pinyin: "Tongling"
    }, {
        citycode: "1892",
        adcode: "469022",
        name: "屯昌县",
        lng: "110.101667",
        lat: "19.351662",
        sname: "屯昌县",
        pinyin: "Tunchangxian"
    }, {
        citycode: "0523",
        adcode: "321200",
        name: "泰州市",
        lng: "119.922883",
        lat: "32.456692",
        sname: "泰州",
        pinyin: "Taizhou"
    }, {
        citycode: "0576",
        adcode: "331000",
        name: "台州市",
        lng: "121.42079",
        lat: "28.655716",
        sname: "台州",
        pinyin: "Taizhou"
    }, {
        citycode: "0315",
        adcode: "130200",
        name: "唐山市",
        lng: "118.180149",
        lat: "39.63068",
        sname: "唐山",
        pinyin: "Tangshan"
    }, {
        citycode: "0938",
        adcode: "620500",
        name: "天水市",
        lng: "105.724828",
        lat: "34.581514",
        sname: "天水",
        pinyin: "Tianshui"
    }, {
        citycode: "022",
        adcode: "120100",
        name: "天津市",
        lng: "117.190182",
        lat: "39.125596",
        sname: "天津",
        pinyin: "Tianjin"
    }, {
        citycode: "0351",
        adcode: "140100",
        name: "太原市",
        lng: "112.549656",
        lat: "37.870451",
        sname: "太原",
        pinyin: "Taiyuan"
    }, {
        citycode: "0435",
        adcode: "220500",
        name: "通化市",
        lng: "125.939721",
        lat: "41.728312",
        sname: "通化",
        pinyin: "Tonghua"
    }]
}, {
    name: "W",
    data: [{
        citycode: "0473",
        adcode: "150300",
        name: "乌海市",
        lng: "106.79415",
        lat: "39.655048",
        sname: "乌海",
        pinyin: "Wuhai"
    }, {
        citycode: "0474",
        adcode: "150900",
        name: "乌兰察布市",
        lng: "113.132227",
        lat: "40.994526",
        sname: "乌兰察布",
        pinyin: "Wulanchabu"
    }, {
        citycode: "0991",
        adcode: "650100",
        name: "乌鲁木齐市",
        lng: "87.616824",
        lat: "43.825377",
        sname: "乌鲁木齐",
        pinyin: "Wulumuqi"
    }, {
        citycode: "1994",
        adcode: "659004",
        name: "五家渠市",
        lng: "87.542852",
        lat: "44.166489",
        sname: "五家渠",
        pinyin: "Wujiaqu"
    }, {
        citycode: "027",
        adcode: "420100",
        name: "武汉市",
        lng: "114.304569",
        lat: "30.593354",
        sname: "武汉",
        pinyin: "Wuhan"
    }, {
        citycode: "0631",
        adcode: "371000",
        name: "威海市",
        lng: "122.120519",
        lat: "37.513315",
        sname: "威海",
        pinyin: "Weihai"
    }, {
        citycode: "0536",
        adcode: "370700",
        name: "潍坊市",
        lng: "119.161721",
        lat: "36.707668",
        sname: "潍坊",
        pinyin: "Weifang"
    }, {
        citycode: "0913",
        adcode: "610500",
        name: "渭南市",
        lng: "109.470962",
        lat: "34.520632",
        sname: "渭南",
        pinyin: "Weinan"
    }, {
        citycode: "0553",
        adcode: "340200",
        name: "芜湖市",
        lng: "118.433065",
        lat: "31.352614",
        sname: "芜湖",
        pinyin: "Wuhu"
    }, {
        citycode: "1898",
        adcode: "469006",
        name: "万宁市",
        lng: "110.392605",
        lat: "18.793697",
        sname: "万宁",
        pinyin: "Wanning"
    }, {
        citycode: "1893",
        adcode: "469005",
        name: "文昌市",
        lng: "110.797473",
        lat: "19.544234",
        sname: "文昌",
        pinyin: "Wenchang"
    }, {
        citycode: "1897",
        adcode: "469001",
        name: "五指山市",
        lng: "109.516784",
        lat: "18.774827",
        sname: "五指山",
        pinyin: "Wuzhishan"
    }, {
        citycode: "0510",
        adcode: "320200",
        name: "无锡市",
        lng: "120.311889",
        lat: "31.491064",
        sname: "无锡",
        pinyin: "Wuxi"
    }, {
        citycode: "0774",
        adcode: "450400",
        name: "梧州市",
        lng: "111.279022",
        lat: "23.476733",
        sname: "梧州",
        pinyin: "Wuzhou"
    }, {
        citycode: "0953",
        adcode: "640300",
        name: "吴忠市",
        lng: "106.198613",
        lat: "37.997755",
        sname: "吴忠",
        pinyin: "Wuzhong"
    }, {
        citycode: "0577",
        adcode: "330300",
        name: "温州市",
        lng: "120.699279",
        lat: "27.993849",
        sname: "温州",
        pinyin: "Wenzhou"
    }, {
        citycode: "1935",
        adcode: "620600",
        name: "武威市",
        lng: "102.637821",
        lat: "37.92898",
        sname: "武威",
        pinyin: "Wuwei"
    }, {
        citycode: "0876",
        adcode: "532600",
        name: "文山壮族苗族自治州",
        lng: "104.21567",
        lat: "23.400983",
        sname: "文山壮族苗族自治州",
        pinyin: "Wenshanzhuangzumiaozuzizhizhou"
    }]
}, {
    name: "X",
    data: [{
        citycode: "0374",
        adcode: "411000",
        name: "许昌市",
        lng: "113.852004",
        lat: "34.03732",
        sname: "许昌",
        pinyin: "Xuchang"
    }, {
        citycode: "0376",
        adcode: "411500",
        name: "信阳市",
        lng: "114.091058",
        lat: "32.148624",
        sname: "信阳",
        pinyin: "Xinyang"
    }, {
        citycode: "0373",
        adcode: "410700",
        name: "新乡市",
        lng: "113.92679",
        lat: "35.303589",
        sname: "新乡",
        pinyin: "Xinxiang"
    }, {
        citycode: "0482",
        adcode: "152200",
        name: "兴安盟",
        lng: "122.037796",
        lat: "46.082373",
        sname: "兴安盟",
        pinyin: "Xinganmeng"
    }, {
        citycode: "0479",
        adcode: "152500",
        name: "锡林郭勒盟",
        lng: "116.047387",
        lat: "43.933212",
        sname: "锡林郭勒盟",
        pinyin: "Xilinguolemeng"
    }, {
        citycode: "2940",
        adcode: "659011",
        name: "新星市",
        lng: "93.74831",
        lat: "42.797043",
        sname: "新星",
        pinyin: "Xinxing"
    }, {
        citycode: "0710",
        adcode: "420600",
        name: "襄阳市",
        lng: "112.121743",
        lat: "32.010161",
        sname: "襄阳",
        pinyin: "Xiangyang"
    }, {
        citycode: "0712",
        adcode: "420900",
        name: "孝感市",
        lng: "113.956962",
        lat: "30.918311",
        sname: "孝感",
        pinyin: "Xiaogan"
    }, {
        citycode: "0728",
        adcode: "429004",
        name: "仙桃市",
        lng: "113.442973",
        lat: "30.328407",
        sname: "仙桃",
        pinyin: "Xiantao"
    }, {
        citycode: "0715",
        adcode: "421200",
        name: "咸宁市",
        lng: "114.322601",
        lat: "29.84135",
        sname: "咸宁",
        pinyin: "Xianning"
    }, {
        citycode: "029",
        adcode: "610100",
        name: "西安市",
        lng: "108.939645",
        lat: "34.343207",
        sname: "西安",
        pinyin: "Xian"
    }, {
        citycode: "0910",
        adcode: "610400",
        name: "咸阳市",
        lng: "108.708837",
        lat: "34.329896",
        sname: "咸阳",
        pinyin: "Xianyang"
    }, {
        citycode: "0563",
        adcode: "341800",
        name: "宣城市",
        lng: "118.759127",
        lat: "30.939278",
        sname: "宣城",
        pinyin: "Xuancheng"
    }, {
        citycode: "0592",
        adcode: "350200",
        name: "厦门市",
        lng: "118.08891",
        lat: "24.479627",
        sname: "厦门",
        pinyin: "Xiamen"
    }, {
        citycode: "0743",
        adcode: "433100",
        name: "湘西土家族苗族自治州",
        lng: "109.737428",
        lat: "28.312592",
        sname: "湘西土家族苗族自治州",
        pinyin: "Xiangxitujiazumiaozuzizhizhou"
    }, {
        citycode: "0732",
        adcode: "430300",
        name: "湘潭市",
        lng: "112.945439",
        lat: "27.83136",
        sname: "湘潭",
        pinyin: "Xiangtan"
    }, {
        citycode: "0516",
        adcode: "320300",
        name: "徐州市",
        lng: "117.283752",
        lat: "34.204224",
        sname: "徐州",
        pinyin: "Xuzhou"
    }, {
        citycode: "0971",
        adcode: "630100",
        name: "西宁市",
        lng: "101.777795",
        lat: "36.616621",
        sname: "西宁",
        pinyin: "Xining"
    }, {
        citycode: "0319",
        adcode: "130500",
        name: "邢台市",
        lng: "114.49742",
        lat: "37.060227",
        sname: "邢台",
        pinyin: "Xingtai"
    }, {
        citycode: "0790",
        adcode: "360500",
        name: "新余市",
        lng: "114.916665",
        lat: "27.818553",
        sname: "新余",
        pinyin: "Xinyu"
    }, {
        citycode: "0350",
        adcode: "140900",
        name: "忻州市",
        lng: "112.734149",
        lat: "38.415958",
        sname: "忻州",
        pinyin: "Xinzhou"
    }, {
        citycode: "0691",
        adcode: "532800",
        name: "西双版纳傣族自治州",
        lng: "100.797002",
        lat: "22.009037",
        sname: "西双版纳傣族自治州",
        pinyin: "Xishuangbannadaizuzizhizhou"
    }]
}, {
    name: "Y",
    data: [{
        citycode: "0662",
        adcode: "441700",
        name: "阳江市",
        lng: "111.98343",
        lat: "21.856853",
        sname: "阳江",
        pinyin: "Yangjiang"
    }, {
        citycode: "0766",
        adcode: "445300",
        name: "云浮市",
        lng: "112.044524",
        lat: "22.915163",
        sname: "云浮",
        pinyin: "Yunfu"
    }, {
        citycode: "0458",
        adcode: "230700",
        name: "伊春市",
        lng: "128.840863",
        lat: "47.728332",
        sname: "伊春",
        pinyin: "Yichun"
    }, {
        citycode: "0999",
        adcode: "654000",
        name: "伊犁哈萨克自治州",
        lng: "81.323691",
        lat: "43.917106",
        sname: "伊犁哈萨克自治州",
        pinyin: "Yilihasakezizhizhou"
    }, {
        citycode: "0717",
        adcode: "420500",
        name: "宜昌市",
        lng: "111.286962",
        lat: "30.69217",
        sname: "宜昌",
        pinyin: "Yichang"
    }, {
        citycode: "0417",
        adcode: "210800",
        name: "营口市",
        lng: "122.219148",
        lat: "40.625027",
        sname: "营口",
        pinyin: "Yingkou"
    }, {
        citycode: "0535",
        adcode: "370600",
        name: "烟台市",
        lng: "121.447755",
        lat: "37.464551",
        sname: "烟台",
        pinyin: "Yantai"
    }, {
        citycode: "0911",
        adcode: "610600",
        name: "延安市",
        lng: "109.49468",
        lat: "36.650109",
        sname: "延安",
        pinyin: "Yanan"
    }, {
        citycode: "0912",
        adcode: "610800",
        name: "榆林市",
        lng: "109.734104",
        lat: "38.28576",
        sname: "榆林",
        pinyin: "Yulin"
    }, {
        citycode: "0730",
        adcode: "430600",
        name: "岳阳市",
        lng: "113.128922",
        lat: "29.35648",
        sname: "岳阳",
        pinyin: "Yueyang"
    }, {
        citycode: "0737",
        adcode: "430900",
        name: "益阳市",
        lng: "112.355994",
        lat: "28.554853",
        sname: "益阳",
        pinyin: "Yiyang"
    }, {
        citycode: "0746",
        adcode: "431100",
        name: "永州市",
        lng: "111.613482",
        lat: "26.419861",
        sname: "永州",
        pinyin: "Yongzhou"
    }, {
        citycode: "0515",
        adcode: "320900",
        name: "盐城市",
        lng: "120.16263",
        lat: "33.348176",
        sname: "盐城",
        pinyin: "Yancheng"
    }, {
        citycode: "0514",
        adcode: "321000",
        name: "扬州市",
        lng: "119.412834",
        lat: "32.394404",
        sname: "扬州",
        pinyin: "Yangzhou"
    }, {
        citycode: "0976",
        adcode: "632700",
        name: "玉树藏族自治州",
        lng: "97.006292",
        lat: "33.006308",
        sname: "玉树藏族自治州",
        pinyin: "Yushucangzuzizhizhou"
    }, {
        citycode: "0775",
        adcode: "450900",
        name: "玉林市",
        lng: "110.18097",
        lat: "22.654001",
        sname: "玉林",
        pinyin: "Yulin"
    }, {
        citycode: "0951",
        adcode: "640100",
        name: "银川市",
        lng: "106.230977",
        lat: "38.487783",
        sname: "银川",
        pinyin: "Yinchuan"
    }, {
        citycode: "0831",
        adcode: "511500",
        name: "宜宾市",
        lng: "104.642826",
        lat: "28.752354",
        sname: "宜宾",
        pinyin: "Yibin"
    }, {
        citycode: "0835",
        adcode: "511800",
        name: "雅安市",
        lng: "103.041538",
        lat: "30.009998",
        sname: "雅安",
        pinyin: "Yaan"
    }, {
        citycode: "0701",
        adcode: "360600",
        name: "鹰潭市",
        lng: "117.039532",
        lat: "28.272092",
        sname: "鹰潭",
        pinyin: "Yingtan"
    }, {
        citycode: "0795",
        adcode: "360900",
        name: "宜春市",
        lng: "114.416826",
        lat: "27.816245",
        sname: "宜春",
        pinyin: "Yichun"
    }, {
        citycode: "0353",
        adcode: "140300",
        name: "阳泉市",
        lng: "113.580426",
        lat: "37.857094",
        sname: "阳泉",
        pinyin: "Yangquan"
    }, {
        citycode: "0359",
        adcode: "140800",
        name: "运城市",
        lng: "111.007051",
        lat: "35.02667",
        sname: "运城",
        pinyin: "Yuncheng"
    }, {
        citycode: "1433",
        adcode: "222400",
        name: "延边朝鲜族自治州",
        lng: "129.470605",
        lat: "42.909426",
        sname: "延边朝鲜族自治州",
        pinyin: "Yanbianchaoxianzuzizhizhou"
    }, {
        citycode: "0877",
        adcode: "530400",
        name: "玉溪市",
        lng: "102.526673",
        lat: "24.346786",
        sname: "玉溪",
        pinyin: "Yuxi"
    }]
}, {
    name: "Z",
    data: [{
        citycode: "0396",
        adcode: "411700",
        name: "驻马店市",
        lng: "114.021988",
        lat: "33.014038",
        sname: "驻马店",
        pinyin: "Zhumadian"
    }, {
        citycode: "0394",
        adcode: "411600",
        name: "周口市",
        lng: "114.701222",
        lat: "33.634652",
        sname: "周口",
        pinyin: "Zhoukou"
    }, {
        citycode: "0371",
        adcode: "410100",
        name: "郑州市",
        lng: "113.625351",
        lat: "34.746303",
        sname: "郑州",
        pinyin: "Zhengzhou"
    }, {
        citycode: "0758",
        adcode: "441200",
        name: "肇庆市",
        lng: "112.465245",
        lat: "23.047747",
        sname: "肇庆",
        pinyin: "Zhaoqing"
    }, {
        citycode: "0756",
        adcode: "440400",
        name: "珠海市",
        lng: "113.576892",
        lat: "22.271644",
        sname: "珠海",
        pinyin: "Zhuhai"
    }, {
        citycode: "0759",
        adcode: "440800",
        name: "湛江市",
        lng: "110.357538",
        lat: "21.270108",
        sname: "湛江",
        pinyin: "Zhanjiang"
    }, {
        citycode: "0760",
        adcode: "442000",
        name: "中山市",
        lng: "113.392517",
        lat: "22.517024",
        sname: "中山",
        pinyin: "Zhongshan"
    }, {
        citycode: "0533",
        adcode: "370300",
        name: "淄博市",
        lng: "118.054994",
        lat: "36.813787",
        sname: "淄博",
        pinyin: "Zibo"
    }, {
        citycode: "0632",
        adcode: "370400",
        name: "枣庄市",
        lng: "117.323759",
        lat: "34.810858",
        sname: "枣庄",
        pinyin: "Zaozhuang"
    }, {
        citycode: "0852",
        adcode: "520300",
        name: "遵义市",
        lng: "107.031922",
        lat: "27.721931",
        sname: "遵义",
        pinyin: "Zunyi"
    }, {
        citycode: "023",
        adcode: "500100",
        name: "重庆城区",
        lng: "106.504962",
        lat: "29.533155",
        sname: "重庆",
        pinyin: "Chongqing"
    }, {
        citycode: "0596",
        adcode: "350600",
        name: "漳州市",
        lng: "117.647298",
        lat: "24.515297",
        sname: "漳州",
        pinyin: "Zhangzhou"
    }, {
        citycode: "0733",
        adcode: "430200",
        name: "株洲市",
        lng: "113.132783",
        lat: "27.828862",
        sname: "株洲",
        pinyin: "Zhuzhou"
    }, {
        citycode: "0744",
        adcode: "430800",
        name: "张家界市",
        lng: "110.478887",
        lat: "29.117343",
        sname: "张家界",
        pinyin: "Zhangjiajie"
    }, {
        citycode: "0511",
        adcode: "321100",
        name: "镇江市",
        lng: "119.424441",
        lat: "32.188141",
        sname: "镇江",
        pinyin: "Zhenjiang"
    }, {
        citycode: "1953",
        adcode: "640500",
        name: "中卫市",
        lng: "105.19677",
        lat: "37.500185",
        sname: "中卫",
        pinyin: "Zhongwei"
    }, {
        citycode: "0580",
        adcode: "330900",
        name: "舟山市",
        lng: "122.207395",
        lat: "29.985578",
        sname: "舟山",
        pinyin: "Zhoushan"
    }, {
        citycode: "0313",
        adcode: "130700",
        name: "张家口市",
        lng: "114.885895",
        lat: "40.768931",
        sname: "张家口",
        pinyin: "Zhangjiakou"
    }, {
        citycode: "0936",
        adcode: "620700",
        name: "张掖市",
        lng: "100.449858",
        lat: "38.924766",
        sname: "张掖",
        pinyin: "Zhangye"
    }, {
        citycode: "0832",
        adcode: "512000",
        name: "资阳市",
        lng: "104.627265",
        lat: "30.129236",
        sname: "资阳",
        pinyin: "Ziyang"
    }, {
        citycode: "0813",
        adcode: "510300",
        name: "自贡市",
        lng: "104.779307",
        lat: "29.33924",
        sname: "自贡",
        pinyin: "Zigong"
    }, {
        citycode: "0870",
        adcode: "530600",
        name: "昭通市",
        lng: "103.717078",
        lat: "27.338185",
        sname: "昭通",
        pinyin: "Zhaotong"
    }]
}];