import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
const _hoisted_1 = {
  class: "order-summary"
};
const _hoisted_2 = {
  class: "summary-item"
};
const _hoisted_3 = {
  class: "summary-value"
};
const _hoisted_4 = {
  class: "summary-item"
};
const _hoisted_5 = {
  class: "summary-value"
};
const _hoisted_6 = {
  class: "summary-item"
};
const _hoisted_7 = {
  class: "summary-value"
};
const _hoisted_8 = {
  class: "summary-item"
};
const _hoisted_9 = {
  class: "summary-value"
};
const _hoisted_10 = {
  class: "summary-item"
};
const _hoisted_11 = {
  class: "summary-value"
};
export default {
  __name: 'order-summary',
  props: {
    orderDetails: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const getPaymentMethod = payments => {
      return payments ? payments.map(payment => payment.payWayName).join(',') : '';
    };
    const getDiningMethod = method => {
      const diningMap = {
        1: '堂食',
        2: '外带'
      };
      return diningMap[method] || '未知就餐方式';
    };
    const copyOrderNo = () => {
      navigator.clipboard.writeText(orderDetails.value.orderNo).then(() => {
        ElMessage({
          message: '订单编号已复制',
          type: 'success'
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "取餐号", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString(__props.orderDetails.pickupNumber), 1)]), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_4, [_cache[1] || (_cache[1] = _createElementVNode("span", null, "下单时间", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(__props.orderDetails.orderTime), 1)]), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_6, [_cache[3] || (_cache[3] = _createElementVNode("span", null, "订单编号", -1)), _createElementVNode("span", _hoisted_7, [_createTextVNode(_toDisplayString(__props.orderDetails.orderNo) + " ", 1), _createVNode(_component_el_button, {
        onClick: copyOrderNo,
        type: "warning",
        size: "small",
        key: "warning",
        link: ""
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("复制")])),
        _: 1
      })])]), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_8, [_cache[4] || (_cache[4] = _createElementVNode("span", null, "就餐方式", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString(getDiningMethod(__props.orderDetails.diningMethod)), 1)]), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_10, [_cache[5] || (_cache[5] = _createElementVNode("span", null, "餐具", -1)), _createElementVNode("span", _hoisted_11, _toDisplayString(__props.orderDetails.tableware || '不需要'), 1)])]);
    };
  }
};