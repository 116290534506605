import request from '@/utils/request'

// 获取附近的门店列表
export function queryNearList(data) {
  return request({
    url: '/bkchina/store/query_near_list',
    method: 'post',
    data: data
  })
}

// 创建订单
export function createOrder(data) {
  return request({
    url: '/bkchina/store/create_order',
    method: 'post',
    data: data
  })
}


// 获取订单详情
export function getOrderDetails(data) {
  return request({
    url: '/bkchina/store/get_order_details',
    method: 'post',
    data: data
  })
}

export function getOrders(data) {
  return request({
    url: '/bkchina/store/getOrderInfo',
    method: 'post',
    data: data
  })
}

export function getOrderDetail(data) {
  return request({
    url: '/bkchina/store/getOrderDetail',
    method: 'post',
    data: data
  })
}
