import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/ico-bk-logo.png';
import _imports_1 from '@/assets/images/ico-qucangui.png';
const _hoisted_1 = {
  class: "dialog-container"
};
const _hoisted_2 = {
  class: "dialog-title"
};
const _hoisted_3 = {
  class: "dialog-content"
};
const _hoisted_4 = {
  class: "store-info"
};
const _hoisted_5 = {
  class: "store-name"
};
const _hoisted_6 = {
  class: "address"
};
import { ref } from 'vue';
import { CircleClose } from '@element-plus/icons-vue';
export default {
  __name: 'pickup-dialog',
  props: {
    restaurant: {
      type: Object,
      required: true
    }
  },
  emits: ['close', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const closeCustomization = () => {
      emit('close');
    };
    const confirm = () => {
      emit('confirm');
      emit('close');
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createBlock(_Transition, {
        name: "slide-up"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "overlay",
          onClick: _withModifiers(closeCustomization, ["self"])
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          alt: "Burger King",
          class: "logo"
        }, null, -1)), _cache[1] || (_cache[1] = _createElementVNode("h3", null, "取餐提醒", -1)), _createVNode(_component_el_icon, {
          class: "close-button",
          onClick: closeCustomization
        }, {
          default: _withCtx(() => [_createVNode(_unref(CircleClose))]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("p", null, [_createTextVNode("本店已开启免接触送餐，请确认门店位置于餐品完成后至门店"), _createElementVNode("span", {
          class: "text-red"
        }, "“取餐柜”"), _createTextVNode("领取")], -1)), _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: _imports_1,
          alt: "Pickup Cabinet",
          class: "cabinet-image"
        }, null, -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("h4", _hoisted_5, _toDisplayString(__props.restaurant.storeName), 1), _createElementVNode("span", _hoisted_6, _toDisplayString(__props.restaurant.address), 1)])]), _createVNode(_component_el_button, {
          type: "success",
          class: "confirm-button",
          round: "",
          onClick: confirm
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("确定")])),
          _: 1
        })])])]),
        _: 1
      });
    };
  }
};