import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import LoadingOverlay from '@/components/LoadingOverlay';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), _createVNode(_unref(LoadingOverlay))], 64);
    };
  }
};