import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "footer"
};
import { useRouter } from 'vue-router';
import { useMainStore } from '@/stores/mainStore';
import StepIndicator from '@/components/StepIndicator';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const store = useMainStore();
    const prevStep = () => {
      router.push('/meals');
    };
    const nextStep = () => {
      router.push('/pickup');
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(StepIndicator), {
        activeStep: 2
      }), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "content"
      }, null, -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
        onClick: prevStep
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("上一步")])),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "primary",
        onClick: nextStep
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("下一步")])),
        _: 1
      })])]);
    };
  }
};