import request from '@/utils/request'

// 获取卡密商品
export function getProductByCode(params) {
  return request({
    url: '/bkchina/store/get_product_by_code',
    method: 'get',
    params: params
  })
}


export function getDisabledList(data) {
  return request({
    url: '/bkchina/store/query_disabled_list',
    method: 'post',
    data
  })
}
