import { unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/loading.gif';
import { useMainStore } from '@/stores/mainStore';
export default {
  __name: 'index',
  setup(__props) {
    const mainStore = useMainStore();
    const loadingClick = () => {
      mainStore.setLoading(false);
    };
    return (_ctx, _cache) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "loading",
        onClick: loadingClick
      }, _cache[0] || (_cache[0] = [_createElementVNode("div", {
        class: "bg"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)]), 512)), [[_vShow, _unref(mainStore).isLoading]]);
    };
  }
};