import { defineStore } from 'pinia'
import axios from 'axios'
import { useStoreStore } from '@/stores/store'
import { ElMessage } from "element-plus";


export const useLocationStore = defineStore('location', {
  state: () => ({
    latitude: null,
    longitude: null,
    city: '',
    selectedCity: null
  }),
  actions: {
    async fetchLocation() {
      try {
        // 获取经纬度
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            timeout: 5000 // 设置超时时间为5秒
          });
        });
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      } catch (error) {
        console.error('Error fetching location:', error);
        ElMessage.info('定位失败，请手动选择城市')
        // 设置默认经纬度值
        this.latitude = 31.230416;
        this.longitude = 121.473701;
      }

      // 判断是否为测试环境
      // const isTestEnv = process.env.VUE_APP_TEST_ENV === 'true';
      // if (isTestEnv) {
      //   console.log("isTestEnv: ", isTestEnv)
      //   this.latitude = 30.156431;
      //   this.longitude = 120.143296;
      // }

      try {
        // 逆地理编码获取城市
        const response = await axios.get(`https://restapi.amap.com/v3/geocode/regeo`, {
          params: {
            key: '8f89d4b7e99fd5a200cbab4f751915a6',
            location: `${this.longitude},${this.latitude}`
          },
          timeout: 5000
        })
        console.log("location: ", response.data)
        const { addressComponent } = response.data.regeocode;
        // 直辖市 city 为空数组
        const city = addressComponent.city.length > 0 ? addressComponent.city : addressComponent.province;
        this.city = typeof city === 'string' ? city : null;

        if(!this.city) {
          ElMessage.warning('获取城市失败，请手动选择');
          return
        }

        // 获取附近的门店列表
        const storeStore = useStoreStore()
        await storeStore.fetchStores(this.city, '', this.longitude, this.latitude)
      } catch (error) {
        console.error('Error fetching location:', error)
      }
    },
    async setSelectedCity(city) {
      this.selectedCity = city
      const storeStore = useStoreStore()
      await storeStore.fetchStores(city.name, '', city.lng, city.lat)
    }
  }
})
