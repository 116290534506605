import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    selectedRestaurant: null,
    selectedMeals: [],
    waitingCode: null,
    isLoading: false
  }),
  actions: {
    setSelectedRestaurant(restaurant) {
      this.selectedRestaurant = restaurant
    },
    setSelectedMeals(meals) {
      this.selectedMeals = meals
    },
    setWaitingCode(code) {
      this.waitingCode = code
    },
    setLoading(isLoading) {
      this.isLoading = isLoading
    }
  }
})