import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/ico-eatIn-order-logo.png';
const _hoisted_1 = {
  class: "restaurant-info"
};
const _hoisted_2 = {
  class: "restaurant-text"
};
const _hoisted_3 = {
  class: "store-name"
};
const _hoisted_4 = {
  class: "address"
};
export default {
  __name: 'order-info',
  props: {
    orderDetails: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(__props.orderDetails.storeName), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(__props.orderDetails.storeAddress), 1)]), _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "eat-in-logo",
        src: _imports_0,
        alt: "Eat In Logo"
      }, null, -1))]);
    };
  }
};