import { defineStore } from 'pinia';

export const useErrorStore = defineStore('error', {
    state: () => ({
        errorCode: '',
        errorMessage: '',
    }),
    actions: {
        setError(code, message) {
            this.errorCode = code;
            this.errorMessage = message;
        },
        clearError() {
            this.errorCode = '';
            this.errorMessage = '';
        }
    }
});
