import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "order-dining-type"
};
import { computed } from 'vue';
import { WarningFilled } from '@element-plus/icons-vue';
export default {
  __name: 'order-dining',
  props: {
    restaurant: {
      type: Object,
      required: true
    },
    diningOption: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const diningType = computed(() => {
      if (props.diningOption === 'dine-in') {
        return '门店柜台取餐';
      } else {
        if (props.restaurant.pickupDiningCabinet === 1) {
          return '自助餐柜取餐';
        } else {
          return '取餐时间';
        }
      }
    });
    const isWarning = computed(() => {
      return props.diningOption === 'takeaway' && props.restaurant.pickupDiningCabinet === 1;
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createBlock(_component_el_card, {
        class: "order-dining"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("span", null, "取餐方式", -1)), _createElementVNode("span", {
          class: _normalizeClass([{
            'warning-text': isWarning.value
          }, "order-dining-type-value"])
        }, [isWarning.value ? (_openBlock(), _createBlock(_component_el_icon, {
          key: 0,
          color: "red"
        }, {
          default: _withCtx(() => [_createVNode(_unref(WarningFilled))]),
          _: 1
        })) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(diningType.value), 1)], 2)]), _createVNode(_component_el_divider), _cache[1] || (_cache[1] = _createElementVNode("div", {
          class: "order-dining-type"
        }, [_createElementVNode("span", null, "取餐时间"), _createElementVNode("span", {
          class: "order-dining-time-value"
        }, "现在取餐")], -1))]),
        _: 1
      });
    };
  }
};