import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "title"
};
import { ArrowLeft } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  props: {
    title: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    },
    showBackButton: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const router = useRouter();
    const goBack = () => {
      router.back();
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_header = _resolveComponent("el-header");
      return _openBlock(), _createBlock(_component_el_header, {
        style: _normalizeStyle({
          backgroundColor: __props.backgroundColor
        }),
        class: "header"
      }, {
        default: _withCtx(() => [__props.showBackButton ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "default",
          class: "back-button",
          onClick: goBack
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_unref(ArrowLeft))]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1)]),
        _: 1
      }, 8, ["style"]);
    };
  }
};