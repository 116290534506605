import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "orders-page"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  key: 0,
  class: "content"
};
const _hoisted_4 = {
  class: "store-name-wrap"
};
const _hoisted_5 = {
  class: "orders"
};
const _hoisted_6 = {
  class: "header"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "products"
};
const _hoisted_9 = {
  key: 0,
  class: "pickup-code"
};
const _hoisted_10 = {
  class: "order-status"
};
const _hoisted_11 = {
  key: 1,
  class: "error-info"
};
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useMainStore } from "@/stores/mainStore";
import { getOrders } from "@/api/store";
import TopNav from "@/components/TopNav/index.vue";
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const code = ref(localStorage.getItem('orderCode') || '');
    const orderInfo = ref();
    const mainStore = useMainStore();
    let IntervalId = null;
    onMounted(async () => {
      mainStore.setLoading(true);
      try {
        await getData();
      } finally {
        mainStore.setLoading(false);
      }
      IntervalId = setInterval(getData, 5000);
    });
    onUnmounted(() => {
      clearInterval(IntervalId);
    });
    async function getData() {
      const {
        data
      } = await getOrders({
        card_code: code.value
      });
      orderInfo.value = data;
    }
    function toDetail(order) {
      if (!order.bkOrderId) {
        ElMessage.warning('下单中，请稍候点击');
        return;
      }
      router.push(`/details/${order.id}`);
    }
    return (_ctx, _cache) => {
      const _component_el_alert = _resolveComponent("el-alert");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(TopNav, {
        title: "订单",
        backgroundColor: "#FFFFFF",
        "show-back-button": false
      })]), orderInfo.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_alert, {
        type: "warning",
        effect: "dark",
        closable: false,
        style: {
          "background": "#fe813b"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.storeName), 1)]),
        _: 1
      })]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderInfo.value.items, order => {
        return _openBlock(), _createElementBlock("div", {
          class: "order",
          key: order.id
        }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("a", {
          onClick: $event => toDetail(order)
        }, " 点我查看详情--------如果餐品数量不对，请重新打开网址，看是否存在多个取餐码! ", 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.products, (product, productIndex) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: productIndex
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.items, (sku, skuIndex) => {
            return _openBlock(), _createElementBlock("span", {
              class: "name",
              key: skuIndex
            }, _toDisplayString(sku.itemSkuName), 1);
          }), 128))], 64);
        }), 128))]), order.pickupNumber ? (_openBlock(), _createElementBlock("div", _hoisted_9, "取餐码：" + _toDisplayString(order.pickupNumber), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_10, _toDisplayString(order.orderStatusDesc), 1), order.failedRemark ? (_openBlock(), _createElementBlock("div", _hoisted_11, " 失败原因：" + _toDisplayString(order.failedRemark), 1)) : _createCommentVNode("", true)]);
      }), 128))])])) : _createCommentVNode("", true)]);
    };
  }
};