import { defineStore } from 'pinia'
import { getOrderDetails } from '@/api/store'
import { useMainStore } from '@/stores/mainStore'
export const useOrderStore = defineStore('order', {
    state: () => ({
        code: localStorage.getItem('orderCode') || '',
        orderStatus: null,
        restaurant: JSON.parse(localStorage.getItem('restaurant')) || {},
        products: JSON.parse(localStorage.getItem('products')) || [],
        orderDetails: null,
        selectedProduct: null,
        selectedItems: {}
    }),
    actions: {
        setCode(code) {
            this.clearOrderData()
            this.code = code
            localStorage.setItem('orderCode', code)
        },
        setOrderStatus(orderStatus) {
            this.orderStatus = orderStatus
        },
        setRestaurant(restaurant) {
            this.restaurant = restaurant
            localStorage.setItem('restaurant', JSON.stringify(restaurant))
        },
        setProducts(products) {
            this.products = products
            localStorage.setItem('products', JSON.stringify(products))
        },
        setSelectedProduct(product) {
            this.selectedProduct = product
        },
        setSelectedItems(items) {
            this.selectedItems = items
        },
        clearOrderData() {
            this.code = ''
            this.orderStatus = null
            this.restaurant = {}
            this.products = []
            this.orderDetails = null
            this.selectedProduct = null
            this.selectedItems = {}
            localStorage.removeItem('orderCode')
            localStorage.removeItem('restaurant')
            localStorage.removeItem('products')
            localStorage.removeItem('selectedItems')
        },
        async fetchOrderDetails(code = null, showLoading = true) {
            const orderCode = code || this.code;
            if (!orderCode) {
                throw new Error('Order code is required');
            }
            const mainStore = useMainStore()
            if (showLoading) {
                mainStore.setLoading(true)
            }
            try {
                const response = await getOrderDetails({ card_code: orderCode })
                if (response.code === 200) {
                    this.orderDetails = response.data.body
                }
            } catch (error) {
                console.error('Error fetching order details:', error)
                throw error
            } finally {
                if (showLoading) {
                    mainStore.setLoading(false)
                }
            }
        }
    }
})
